import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./extraReducers";
import reducers from "./reducers";

const initialState = {
    data: [],
    channel:null,
    channelAgentStatus:null,
    currentSession: undefined,
    currentMessage: undefined,
    chatHistory: [],
    queueMessages: [],
    matchFile: null,
    AIresponse: null,
    sampleRecords: [],
    recentMessageInfo: null,
    downloadType: "",
    status: {
      get: undefined,
      create: "idle",
      edit: "idle",
      pin: "idle",
      delete: "idle",
      postMessage: "idle",
      chatHistory: "idle",
      matchFile: "idle",
      sampleRecords: "idle",
    },
    processing: {
      create: { id: null },
      edit: { id: null },
      pin: { id: null },
      delete: { id: null },
      postMessage: { id: null },
      chatHistory: { id: null },
      matchFile: null,
      sampleRecords: null,
    },
  }

export const sessionsSlice = createSlice({
  name: "sessions",
  initialState,
  reducers,
  extraReducers
});

export default sessionsSlice;
