// SAMPLE MATCHFILE
export const getSamplePending = (state, action) => {
  state.status.sampleRecords = "pending";
};
export const getSampleFulfilled = (state, action) => {
  state.status.sampleRecords = "fulfilled";
  state.sampleRecords = action.payload;
};
export const getSampleRejected = (state, action) => {
  state.status.sampleRecords = "idle";
};
