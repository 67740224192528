import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./extraReducers";
import reducers from "./reducers";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    data: {},
    status: undefined,
    account: {
      data: null,
      status: "idle",
    },
    azureProfile: {
      data: null,
      status: "pending",
    },
  },
  reducers,
  extraReducers,
});

export default authSlice;
