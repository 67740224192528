import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import ModeIcon from "@mui/icons-material/Mode";
import { useEditSessionHandler } from "../hooks/useEditSessionHandler";
import { useEffect, useState } from "react";

export default function NameSession({ session, canEdit }) {
  const [edit, setEdit] = useState(false)
  const {isLoading,isSuccess, onEditSession, onResetStatus } = useEditSessionHandler()
  useEffect(()=>{
    if(isSuccess && edit){
      setEdit(false);
    }
  },[isSuccess, edit])
  const theme = useTheme()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { name: session.name },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
    }),
    onSubmit: (values) => {
      onEditSession(session.id, values)
    },
  });
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      formik.submitForm()
    }
  };

  return edit ? (
      <Paper>
        <TextField
          autoFocus
          disabled={isLoading}
          name={"name"}
          value={formik.values.name}
          onChange={formik.handleChange}
          onKeyDown={handleKeyDown}
          onBlur={(e, el) => {
            if (formik.initialValues.name !== formik.values.name) {
              formik.submitForm();
              return;
            }
            formik.resetForm();
            setEdit(false);
          }}
          error={formik.touched.name && Boolean(formik.errors.name)}
          inputProps={{ sx: { py: 0.5, px: 2 } }}
          InputProps={{
            endAdornment: (
              <Box sx={{ p: 1 }}>
                {isLoading && (
                  <CircularProgress
                    size={15}
                    color="primary"
                    variant="indeterminate"
                  />
                )}
              </Box>
            ),
          }}
        ></TextField>
      </Paper>
  ) : (
    <Box>
      <Typography sx={{
        color:theme.palette.info.contrastText
      }} variant="overline" fontSize={'10px'} display="block" textAlign={"left"}>
        {session.name}
       {canEdit && <IconButton
          aria-label="edit"
          sx={{ color: theme.palette.info.contrastTextLight }}
          size="small"
          onClick={() => {
            setEdit(true)
            onResetStatus()
          }}
        >
          <ModeIcon fontSize="inherit" />
        </IconButton>} 
      </Typography>
    </Box>
  );
}
