import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  Box,
  Divider,
  List,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSendAskHelpHandler } from "./hooks/useSendAskHelpHandler";
import { useFormik } from "formik";
import * as Yup from "yup";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import BuildIcon from "@mui/icons-material/Build";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SmartToyIcon from '@mui/icons-material/SmartToy';
function HightLightText({ children }) {
  const theme = useTheme();
  return (
    <b
      style={{
        color: theme.palette.secondary.main,
        fontWeight: "normal",
      }}
    >
      {children}
    </b>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, height: "450px" }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HelpChatBubble = ({ text, isHuman, isIA }) => {
  const theme = useTheme();
  return (
    <>
      {isIA && (
        <Box
          sx={{
            my: 3,
            display: "flex",
            alignContent: "flex-start",
            flexDirection: "row",
          }}
          maxWidth={"100%"}
        >
          <Box maxWidth={"70%"}>
            <Box
              position="absolute"
              sx={{
                backgroundImage: "url('/top-vector-tip-white.png')",
                height: "10px",
                width: "30px",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
              left={"31px"}
            />

            <Box
              borderRadius={3}
              sx={{
                p: 3,
                width: "auto",
                bgcolor: theme.palette.info.light,
                mx: 5,
              }}
            >
              <Typography color={"black"} whiteSpace={"break-spaces"}>
                {text}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      {isHuman && (
        <Box
          sx={{
            my: 3,
            display: "flex",
            alignContent: "flex-start",
            flexDirection: "row-reverse",
          }}
          maxWidth={"100%"}
        >
          <Box maxWidth={"70%"}>
            <Box
              position="absolute"
              sx={{
                backgroundImage: "url('/top-vector-tip.png')",
                height: "10px",
                width: "30px",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
              right={"22px"}
            />
            <Box
              borderRadius={3}
              sx={{
                p: 3,
                width: "auto",
                background: `linear-gradient(90deg, ${theme.palette.primary.main} 9.65%, ${theme.palette.secondary.main} 92.4%)`,
                mx: 5,
              }}
            >
              <Typography
                color={theme.palette.info.contrastTextLight}
                whiteSpace={"break-spaces"}
              >
                {text}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default function HelpModal({ buttonComponent }) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const [responseText, setResponseText] = React.useState("");
  const languages = [
    {
      language: "🇬🇧 English",
      video:
        "https://stfilesdevuksouth002prem.blob.core.windows.net/user-guide/User%20Demo%20-%201715182033985%20(1).mp4?sp=r&st=2024-05-07T21:56:39Z&se=3000-05-09T05:56:39Z&spr=https&sv=2022-11-02&sr=b&sig=ZJpXPH2nKdBrp3%2BV7Aom3qH3fZVkGNU4hTkJkjRv2vw%3D",
    },
    {
      language: "🇯🇵 Japanese",
      video:
        "https://stfilesdevuksouth002prem.blob.core.windows.net/user-guide/User%20Demo%20Japanese%20-%201715271969925.mp4?sp=r&st=2024-05-08T18:40:02Z&se=3000-05-10T02:40:02Z&spr=https&sv=2022-11-02&sr=b&sig=VmsQsDaRzJssA6Gnnhb543IsdAS1vuwKBjjxtwUpDf8%3D",
    },
  ];
  const [language, setLanguage] = React.useState(languages[0]);

  const handleLanguageChange = (event, newLanguage) => {
    setLanguage(languages.find((item) => newLanguage === item.language));
  };
  const messagesEndRef = React.useRef(null);
  const scrollToBottom = (auto) => {
    messagesEndRef.current?.scrollIntoView({
      behavior: auto ? "auto" : "smooth",
    });
  };

  React.useEffect(() => {
    if (scrollToBottom) {
      scrollToBottom();
    }
  }, [responseText]); 

  const {
    history,
    lastMessage,
    isLoading,
    isSuccess,
    isStreaming,
    isUninitialized,
    onPostChat,
    onSetHelpMessageStatus,
    onResetHistory,
  } = useSendAskHelpHandler();

  const handleChange = (_, newValue) => {
    setValue(newValue);
    onResetHistory();
    formik.resetForm();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    if(isLoading || isStreaming) return
    onSetHelpMessageStatus("idle");
    setOpen(false);
    onResetHistory();
    formik.resetForm();
    setResponseText("");
  };


React.useEffect(() => {
  if(isSuccess){
    onSetHelpMessageStatus('idle')
  }
},[isSuccess, onSetHelpMessageStatus])
  React.useEffect(() => {
    const getMessage = async () => {
      if (lastMessage !== null) {
        if (lastMessage?.isStream && lastMessage?.text) {
          while (true) {
            const { done, value } = await lastMessage.text.read();
            if (done) {
              onSetHelpMessageStatus("fulfilled");
              break;
            }
            const text = new TextDecoder().decode(value);
            setResponseText((prevText) => `${prevText ? prevText : ""}${text}`); // Update the state with the new text
          }
        } else {
          //setResponseText(lastMessage.text);
        }
      }
    };
    getMessage();
  }, [lastMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const buttonWithOnClick = React.cloneElement(buttonComponent, {
    onClick: handleClickOpen,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      prompt: "",
    },
    validationSchema: Yup.object().shape({
      prompt: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      setResponseText("");
      onResetHistory();
      onPostChat(values);
      formik.resetForm()
    },
  });

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      submit()
    }
  };

  const submit =()=>{
    if((isLoading || isStreaming)) return
    if(isUninitialized){
      formik.submitForm()
    }
  }
  return (
    <React.Fragment>
      {buttonWithOnClick}
      <Dialog
        open={open}
        fullWidth
        maxWidth={"lg"}
        keepMounted
        onClose={handleClose}
        sx={{ zIndex: 10000 }}
      >
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Help Chat" {...a11yProps(0)} />
              <Tab label="Video Demo" {...a11yProps(1)} />
              <Tab label="User Guide" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <List sx={{ height: "300px", overflowY: "auto" }}>
              {history.length === 0 &&<>
               <Box sx={{
                color:'gray',
                height: '100%',
                borderRadius: '10px',
                background: '#fafafa',
                justifyContent:'center',
                alignItems:"center",
                display:"flex",
                flexDirection:'column'
               }}>
               <Box sx={{
                background: '#e7e7e7',
                padding: '10px',
                borderRadius: '10px',
                margin: '10px'
               }}>
               <SmartToyIcon fontSize="large"></SmartToyIcon>
                <ContactSupportIcon sx={{marginBottom:'13px'}}fontSize="medium"></ContactSupportIcon></Box>
               <Box>
                User Guide AI Chat Bot
               </Box>
               </Box>
              </>}
              {history.map((item, idx) => (
                <Box key={idx}>
                    <HelpChatBubble
                      text={item.isIA && item.isStream ? responseText : item.text}
                      isHuman={item.isHuman}
                      isIA={item.isIA}
                    />
                    <Box ref={messagesEndRef}></Box>
                </Box>
              ))}
            </List>
            <Box sx={{ height: "15px", p: 1 }}>
              {isLoading ? "Typing..." : ""}
            </Box>

            <Box sx={{ width: "100%", display: "flex" }}>
              <TextField
                name={"prompt"}
                placeholder="Please ask me any questions relating to the User Guide for this application."
                value={formik.values.prompt}
                onChange={formik.handleChange}
                onKeyDown={handleKeyDown}
                fullWidth
              ></TextField>
              <Button onClick={() => submit()}>Send</Button>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <video
              style={{
                background: "black",
                borderRadius: "20px",
              }}
              width={"100%"}
              height={"400px"}
              controls
              src={language.video}
            ></video>
            <br />
            <br />

            <ToggleButtonGroup
              color="third"
              value={language.language}
              size="small"
              exclusive
              onChange={handleLanguageChange}
            >
              {languages.map((val, idx) => (
                <ToggleButton key={idx} value={val.language}>
                  {val.language}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
          <Divider textAlign="center">
                <Typography
                  color={"third.main"}
                  textAlign={"center"}
                  fontWeight={"520"}
                  variant="h5"
                >
                  User Guide
                </Typography>
                <br/>
              </Divider>
              <Typography variant="body2" textAlign={"center"}>
                The User Guide for the Fujitsu Legal AI Chatbot can be accessed
                below. This User Guide can also be queried via the “Help Chat”
                tab.
              </Typography>
              <br />
              <Divider></Divider>
            <Box sx={{ height: "420px", overflow: "auto" }}>
              
              <Typography fontWeight={"520"} variant="h6" color={"third.main"}>
                Important Warning: Customer Data and Personal Data
              </Typography>

              <Typography variant="body2">
                Please do not enter any{" "}
                <HightLightText>customer data</HightLightText> e.g.{" "}
                <HightLightText>customer company name</HightLightText>{" "}
                or <HightLightText>organisation name</HightLightText> when using
                the Fujitsu Legal AI chatbot. Please use generic terms while
                referring to customers, e.g. “a Fujitsu customer”. When
                describing a customer contract provision as part of your
                question, please do not enter the customer organisation name.
              </Typography>
              <br/>
              <Typography variant="body2">
                Please do not enter any{" "}
                <HightLightText>personal data</HightLightText> when using this
                chatbot.
              </Typography>
              <br />
              <br />
              <Typography fontWeight={"520"} variant="h6" color={"third.main"}>
                About the Legal AI Chatbot
              </Typography>
              <Typography variant="body2">
                The chatbot is intended to answer the legal team’s questions in
                relation to Fujitsu’s{" "}
                <HightLightText>preferred contractual positions</HightLightText>{" "}
                and <HightLightText>risk levels</HightLightText>, based on the
                Legal Function’s <HightLightText>Deal Playbook</HightLightText>.
                In future, the chatbot’s source data will be expanded to include
                select Knowledge Share articles.
              </Typography>
              <br />
              <Typography variant="body2">
                When asked a question about Fujitsu’s position on a contractual
                topic, the chatbot generates its response in a conversational
                format, and based entirely on the Deal Playbook. It also
                provides the <HightLightText>sources</HightLightText> for its
                answers – it names the Deal Playbook chapter(s) and section(s)
                which the answer is based on.
              </Typography>
              <br />
              <Typography variant="body2">
                This application uses the <HightLightText>GPT4</HightLightText>{" "}
                model via <HightLightText>Azure OpenAI</HightLightText>, using{" "}
                <HightLightText>
                  Retrieval Augmented Generation
                </HightLightText>{" "}
                (“RAG”) architecture. This ensures that we can access trusted
                Fujitsu data while leveraging the generative power of OpenAI
                models combined with Microsoft Azure security features.
              </Typography>
              <br />
              <Typography fontWeight={"520"} variant="h6" color={"third.main"}>
                How to Use this Application
              </Typography>
              <Typography variant="body2">
                Please view the short{" "}
                <HightLightText>user demo video</HightLightText> on this site
                for guidance on how to use the application. You can log into the
                application (
                <a href="https://asklegal-ai.com/">https://asklegal-ai.com/</a>)
                using your Fujitsu Microsoft 365 account (via Single Sign-On).
              </Typography>
              <br />
              <Typography fontWeight={"520"} variant="h6" color={"third.main"}>
                Source of the Chatbot’s Answers
              </Typography>
              <Typography variant="body2">
                The chatbot’s responses are currently based entirely on the
                Fujitsu Legal Function’s{" "}
                <HightLightText>Deal Playbook</HightLightText>. The chatbot has
                been designed not to draw from any other sources, including
                publicly available information from the internet. If asked a
                question which cannot be answered based on the Deal Playbook,
                the chatbot will state that the source data does not contain the
                relevant information. In future, the chatbot’s sources will be
                extended to include select Knowledge Share articles.
              </Typography>
              <br />
              <Typography variant="body2">
                While this application has been designed to substantially
                mitigate response inaccuracy and to avoid “hallucinations”, the
                very nature of generative AI means that 100% accuracy cannot be
                guaranteed. Therefore, users should always check the{" "}
                <HightLightText>source material cited{" "}</HightLightText> in the
                answers to <HightLightText>validate</HightLightText> the
                accuracy of the responses.
              </Typography>

              <br />
              <Typography fontWeight={"520"} variant="h6" color={"third.main"}>
                Best Practices
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <VerifiedUserIcon
                  fontSize="12px"
                  sx={{ color: theme.palette.secondary.main, pr: 1 }}
                />{" "}
                <Typography variant="subtitle1" color="third.main">
                  Effective Prompts
                </Typography>
              </Box>
              <Typography variant="body2">
                A <HightLightText>prompt</HightLightText> is a statement,
                question, or set of instructions given to a{" "}
                <HightLightText>generative AI application</HightLightText> to
                receive the desired output from the application. Writing
                effective prompts significantly increases a user’s chances of
                getting better responses. Please review the{" "}
                <HightLightText>Prompting Guidelines</HightLightText> set out in
                the panel on the{" "}
                <HightLightText>right-hand side</HightLightText> of the
                application screen. These guidelines have been specifically
                tailored to help you achieve optimal results from the Legal AI
                Chatbot.
              </Typography>
              <br />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <VerifiedUserIcon
                  fontSize="12px"
                  sx={{ color: theme.palette.secondary.main, pr: 1 }}
                />{" "}
                <Typography variant="subtitle1" color="third.main">
                  Source Validation
                </Typography>
              </Box>
              <Typography variant="body2">
                While this application has been designed to substantially
                mitigate response inaccuracy, the very nature of generative AI
                means that 100% accuracy cannot be guaranteed. The creative,
                synthesising nature of generative AI means that responses may at
                times contain some inaccuracies.
              </Typography>
              <br />
              <Typography variant="body2">
                Therefore, users should always check{" "}
                <HightLightText>the source material cited{" "}</HightLightText>
                in the answers to <HightLightText>validate</HightLightText> the
                accuracy of the responses. The relevant Deal Playbook chapter(s)
                and section(s) used in the response will be listed at the bottom
                of the response. You can access the Deal Playbook from the link
                in the panel on the right-hand side of the application screen,
                to cross-check the references.
              </Typography>
              <br />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <VerifiedUserIcon
                  fontSize="12px"
                  sx={{ color: theme.palette.secondary.main, pr: 1 }}
                />{" "}
                <Typography variant="subtitle1" color="third.main">
                  “Idle” Status
                </Typography>
              </Box>

              <Typography variant="body2">
                Please <HightLightText>log out</HightLightText> and log back in
                if the application has been idle on your browser for an extended
                period. This reduces the potential for technical glitches when
                interacting with the application.
              </Typography>
              <br />
              <Typography fontWeight={"520"} variant="h6" color={"third.main"}>
                Saving or “Pinning” Conversations
              </Typography>
              <Typography variant="body2">
                You can save conversations by{" "}
                <HightLightText>“pinning”</HightLightText> them (please view the
                user demo video to check how to do this). You may pin up to a
                maximum of <HightLightText>5 conversations</HightLightText>,
                which will be visible in the application each time you log in.
              </Typography>
              <br />
              <Typography fontWeight={"520"} variant="h6" color={"third.main"}>
                Automatically Saved Conversations
              </Typography>
              <Typography variant="body2">
                Apart from the pinned conversations, the application will also
                remember a user’s <HightLightText>5 most recent</HightLightText>
                (unpinned) <HightLightText>conversations</HightLightText>.
                Although more than 5 (unpinned) conversations may be visible in
                the course of the day, overnight, conversations prior to the
                last 5 will be deleted.
              </Typography>
              <br />
              <Typography fontWeight={"520"} variant="h6" color={"third.main"}>
                Follow-up Questions versus New Conversations
              </Typography>
              <Typography variant="body2">
                You can ask <HightLightText>follow-up questions</HightLightText>{" "}
                under previous responses, for instance to{" "}
                <HightLightText>expand</HightLightText> or{" "}
                <HightLightText>clarify</HightLightText> an element of a
                response. Please note that this application is optimised for up
                to <HightLightText>3 follow-up{" "}</HightLightText>
                questions. Beyond 3 follow-up questions, the quality of the
                responses may be sub-optimal.
              </Typography>
              <br />
              <Typography variant="body2">
                If you have a question on a new topic or have a follow-up
                question beyond 3 follow-ups, you should start a{" "}
                <HightLightText>new conversation</HightLightText>.
              </Typography>
              <br />
              <Typography fontWeight={"520"} variant="h6" color={"third.main"}>
                Troubleshooting
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BuildIcon
                  fontSize="12px"
                  sx={{ color: theme.palette.secondary.main, pr: 1 }}
                />{" "}
                <Typography variant="subtitle1" color="third.main">
                  Unexpected or Nil Answers
                </Typography>
              </Box>
              <Typography variant="body2">
                If the expected answer is not received in the first attempt, or
                the chatbot responds that the source data does not contain the
                relevant information, you should try asking the question again
                using{" "}
                <HightLightText>
                  different/additional terminology
                </HightLightText>
                . You could try using terms that are more likely to have been
                used in the source material (currently, the Deal Playbook). If
                that does not work, the chatbot source may not in fact contain
                the information sought and you should explore other sources for
                your research.
              </Typography>
              <br />
              <Typography variant="body2">
                Please note that when asked very broadly scoped questions (e.g.:
                “Summarise the Deal Playbook”), the application can sometimes
                struggle to identify the highest priority information to
                summarise in its brief response. We are working on this.
                Meanwhile, at present, more targeted questions will elicit
                better responses. Please read the Prompting Guidelines on the
                application page for prompting tips.
              </Typography>
              <br />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BuildIcon
                  fontSize="12px"
                  sx={{ color: theme.palette.secondary.main, pr: 1 }}
                />{" "}
                <Typography variant="subtitle1" color="third.main">
                  Technical Errors in Displaying Question/Response
                </Typography>
              </Box>
              <Typography variant="body2">
                If you experience any technical errors, for e.g. if the typed
                questions or saved conversations are not being displayed, this
                may be due to the application having been idle for an extended
                period. Please <HightLightText>log out</HightLightText> and log
                back in, and if the error persists, please reach out via the
                application website for{" "}
                <HightLightText>technical support</HightLightText>.
              </Typography>
              <br />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BuildIcon
                  fontSize="12px"
                  sx={{ color: theme.palette.secondary.main, pr: 1 }}
                />{" "}
                <Typography variant="subtitle1" color="third.main">
                  Responses Not Streaming (not Displaying Word-by-Word)
                </Typography>
              </Box>
              <Typography variant="body2">
                When used in some of Fujitsu’s office locations, the corporate
                network VPN seems to prevent word- by-word response streaming –
                a submitted question is followed by a lag and the answer then
                appears altogether in a single block of text. The quality of the
                response is not affected.
              </Typography>
              <Typography variant="body2">
                When the Legal AI Chatbot is accessed outside Fujitsu office
                locations via the Global Protect VPN, the responses are faster,
                with word-by-word streaming as designed.
              </Typography>
              <br />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BuildIcon
                  fontSize="12px"
                  sx={{ color: theme.palette.secondary.main, pr: 1 }}
                />{" "}
                <Typography variant="subtitle1" color="third.main">
                  Long Response Times
                </Typography>
              </Box>
              <Typography variant="body2">
                The response times in this application are slightly slower than
                ChatGPT/ChatAI. This is because Retrieval Augmented Generation
                (“RAG”) applications such as the Legal AI Chatbot, where an LLM
                model is linked to a specific proprietary data source, are
                typically slower. Within those constraints, this application has
                been optimised for speed compared to other comparable
                applications.
              </Typography>
              <br />
              <Typography fontWeight={"520"} variant="h6" color={"third.main"}>
                Next Steps
              </Typography>
              <Typography variant="body2">
                Please watch the{" "}
                <HightLightText>user demo video</HightLightText> and read the
                <HightLightText> Prompting Guidelines</HightLightText> (both
                available on the application site) and{" "}
                <HightLightText>try the application</HightLightText> as often as
                you can. To build familiarity with this application, you could
                pick a topic from the Deal Playbook and ask the chatbot
                different types of questions about that topic. Compare the
                responses received with the Deal Playbook source material, to
                get a sense of the sort of answers you can expect.
              </Typography>
              <br />
              <Typography variant="body2">
                The more you familiarise yourself with this chatbot, the easier
                it will be for you to use it effectively when needed!
              </Typography>
            </Box>
          </CustomTabPanel>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
