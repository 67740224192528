import { sessionAPIs } from "../../../api";
import {
  getSessionsFulfilled,
  getSessionsPending,
  getSessionsRejected,
} from "./cases/getSessions";
import {
  createSessionFulfilled,
  createSessionPending,
  createSessionRejected,
  createSessionSettled,
} from "./cases/createSession";
import {
  putSessionFulfilled,
  putSessionPending,
  putSessionRejected,
} from "./cases/putSession";
import {
  putPinSessionsFulfilled,
  putPinSessionsPending,
  putPinSessionsRejected,
} from "./cases/putPinSession";
import {
  deleteSessionFulfilled,
  deleteSessionPending,
  deleteSessionRejected,
} from "./cases/deleteSession";
import { 
    postMessageSessionFulfilled,
    postMessageSessionPending,
    postMessageSessionRejected
} from "./cases/postMessage";
import { 
  getChatMessageSessionFulfilled,
  getChatMessageSessionPending,
  getChatMessageSessionRejected 
} from "./cases/getChatMessageSession";
import { 
  getCheckpointMessageHistoryfulfilled,
  getCheckpointMessageHistorypending,
  getCheckpointMessageHistoryrejected 
} from "./cases/getCheckpointMessageHistory";
import { 
  downloadFileFulfilled,
  downloadFilePending,
  downloadFileRejected
} from "./cases/downloadFile";
import { 
  getSampleFulfilled,
  getSamplePending,
  getSampleRejected 
} from "./cases/getSample";
import { 
  deleteSessionSettled,
  getChatMessageSessionSettled,
  postMessageSessionSettled,
  putPinSessionsSettled,
  putSessionSettled 
} from "./cases/finalEventCases";

export const extraReducers = (builder) => {
  builder.addCase(sessionAPIs.getSessions.pending, getSessionsPending);
  builder.addCase(sessionAPIs.getSessions.fulfilled, getSessionsFulfilled);
  builder.addCase(sessionAPIs.getSessions.rejected, getSessionsRejected);
  // CREATE SESSION
  builder.addCase(sessionAPIs.createSession.pending, createSessionPending);
  builder.addCase(sessionAPIs.createSession.fulfilled, createSessionFulfilled);
  builder.addCase(sessionAPIs.createSession.rejected, createSessionRejected);
  // EDIT SESSION
  builder.addCase(sessionAPIs.putSession.pending, putSessionPending);
  builder.addCase(sessionAPIs.putSession.fulfilled, putSessionFulfilled);
  builder.addCase(sessionAPIs.putSession.rejected, putSessionRejected);
  // PIN SESSION
  builder.addCase(sessionAPIs.putPinSessions.pending, putPinSessionsPending);
  builder.addCase(sessionAPIs.putPinSessions.fulfilled, putPinSessionsFulfilled);
  builder.addCase(sessionAPIs.putPinSessions.rejected, putPinSessionsRejected);
  // DELETE SESSION
  builder.addCase(sessionAPIs.deleteSession.pending, deleteSessionPending);
  builder.addCase(sessionAPIs.deleteSession.fulfilled, deleteSessionFulfilled);
  builder.addCase(sessionAPIs.deleteSession.rejected, deleteSessionRejected);
  // SEND MEESAGE
  builder.addCase(sessionAPIs.postMessageSession.pending, postMessageSessionPending);
  builder.addCase(sessionAPIs.postMessageSession.fulfilled, postMessageSessionFulfilled);
  builder.addCase(sessionAPIs.postMessageSession.rejected, postMessageSessionRejected);
  // CHAT HISTORY MEESAGE
  builder.addCase(sessionAPIs.getChatMessageSession.pending, getChatMessageSessionPending);
  builder.addCase(sessionAPIs.getChatMessageSession.fulfilled, getChatMessageSessionFulfilled);
  builder.addCase(sessionAPIs.getChatMessageSession.rejected, getChatMessageSessionRejected);
  // CHECKPOINT MEESAGE
  builder.addCase(sessionAPIs.getCheckpointMessageHistory.pending, getCheckpointMessageHistorypending);
  builder.addCase(sessionAPIs.getCheckpointMessageHistory.fulfilled, getCheckpointMessageHistoryfulfilled);
  builder.addCase(sessionAPIs.getCheckpointMessageHistory.rejected, getCheckpointMessageHistoryrejected);
  // DOWNLOAD MATCHFILE
  builder.addCase(sessionAPIs.downloadFile.pending, downloadFilePending);
  builder.addCase(sessionAPIs.downloadFile.fulfilled, downloadFileFulfilled);
  builder.addCase(sessionAPIs.downloadFile.rejected, downloadFileRejected);
  // SAMPLE MATCHFILE
  builder.addCase(sessionAPIs.getSample.pending, getSamplePending);
  builder.addCase(sessionAPIs.getSample.fulfilled, getSampleFulfilled);
  builder.addCase(sessionAPIs.getSample.rejected, getSampleRejected);

  // RESET STATUS IN FINALLY EVENTS
  builder.addMatcher(sessionAPIs.createSession.settled, createSessionSettled);
  builder.addMatcher(sessionAPIs.putSession.settled, putSessionSettled);
  builder.addMatcher(sessionAPIs.deleteSession.settled, deleteSessionSettled);
  builder.addMatcher(sessionAPIs.putPinSessions.settled, putPinSessionsSettled);
  builder.addMatcher(sessionAPIs.getChatMessageSession.settled, getChatMessageSessionSettled);
  builder.addMatcher(sessionAPIs.postMessageSession.settled, postMessageSessionSettled);
};
