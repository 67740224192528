import { accountAPIs, azureGraphAPI } from "../../../api";
import {
  getAzureUserProfileFulfilled,
  getAzureUserProfilePending,
  getAzureUserProfileRejected,
} from "./cases/getAzureUserProfile";
import {
  postAccountCheckPending,
  postAccountCheckFulfilled,
  postAccountCheckRejected,
} from "./cases/postAccountCheck";

export const extraReducers = (builder) => {
  // AZURE GET PROFILE
  builder.addCase(
    azureGraphAPI.getAzureUserProfile.pending,
    getAzureUserProfilePending
  );
  builder.addCase(
    azureGraphAPI.getAzureUserProfile.fulfilled,
    getAzureUserProfileFulfilled
  );
  builder.addCase(
    azureGraphAPI.getAzureUserProfile.rejected,
    getAzureUserProfileRejected
  );

  // ACCOUNT CHECK
  builder.addCase(
    accountAPIs.postAccountCheck.pending,
    postAccountCheckPending
  );
  builder.addCase(
    accountAPIs.postAccountCheck.fulfilled,
    postAccountCheckFulfilled
  );
  builder.addCase(
    accountAPIs.postAccountCheck.rejected,
    postAccountCheckRejected
  );
};
