import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  FormControl,
  InputLabel,
  LinearProgress,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAuthHandler from "../../hooks/useAuthHandler";
import { useSendEmailHandler } from "./hooks/useFeedbackHandler";
import EngineeringIcon from "@mui/icons-material/Engineering";
import useCurrentSessionHandler from "../../../pages/private/main/sessions/hooks/useCurrentSessionHandler";

export default function TechnicalSupportModal({ buttonComponent }) {
  const [open, setOpen] = React.useState(false);
  const { isLoading, isSuccess, resetStatus, onSendTechnicalSupportEmail } =
    useSendEmailHandler();
  const { currentSession } = useCurrentSessionHandler();
  const { azureProfileData } = useAuthHandler();

  const handleClickOpen = () => {
    setOpen(true);
    formik.setTouched("type");
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: azureProfileData?.displayName,
      message: "",
      type: "",
      emailType: "technical_support",
      metadata: JSON.stringify({
        sessionId: currentSession?.id,
        lastMessage: currentSession?.messages.length
          ? currentSession?.messages[currentSession?.messages.length - 1]
          : null,
      }),
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      type: Yup.string().required("Required"),
      message: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      onSendTechnicalSupportEmail({ ...values });
    },
  });
  React.useEffect(() => {
    if (currentSession?.id !== JSON.parse(formik.values.metadata)?.sessionId) {
      formik.setFieldValue(
        "metadata",
        JSON.stringify({
          sessionId: currentSession?.id,
          lastMessage: currentSession?.messages.length
            ? currentSession?.messages[currentSession?.messages.length - 1]
            : null,
        })
      );
    }
  }, [currentSession?.id, currentSession?.messages, formik]);

  const handleClose = React.useCallback(() => {
    formik.resetForm();
    resetStatus();
    setOpen(false);
  }, [formik, resetStatus]);

  React.useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess, handleClose]);

  const buttonWithOnClick = React.cloneElement(buttonComponent, {
    onClick: handleClickOpen,
  });
  return (
    <React.Fragment>
      {buttonWithOnClick}
      <Dialog
        open={open}
        fullWidth
      //  sx={{ zIndex: 10 }}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogTitle>
            <EngineeringIcon /> {"Technical Support"}
          </DialogTitle>
          <Stack spacing={5}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <TextField
                label="Name"
                variant="outlined"
                name={"name"}
                value={formik.values.name}
                onChange={formik.handleChange}
                autoComplete="off"
              />
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel htmlFor="grouped-native-select">
                Support Help Email Options
              </InputLabel>
              <Select
                
                id="grouped-native-select"
                label="Support Help Email Options"
                name={"type"}
                value={formik.values.type}
                onChange={formik.handleChange}
              >
                <MenuItem value=""></MenuItem>
                <ListSubheader sx={{fontWeight:"bold", fontSize:'15px'}} >Functionality</ListSubheader>
                <MenuItem sx={{pl:"30px"}} value={"Defective Feature / Error Message"}>
                 {" "}Defective Feature / Error Message
                </MenuItem>
                <ListSubheader sx={{fontWeight:"bold", fontSize:'15px'}} >Performance</ListSubheader>
                <MenuItem sx={{pl:"30px"}} value={"Slow"}>Slow</MenuItem>
                <MenuItem sx={{pl:"30px"}} value={"Not Returning Results"}>
                  {" "}
                  Not Returning Results
                </MenuItem>
                <MenuItem sx={{pl:"30px"}} value={"Other"}><b>Other</b></MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Message"
              variant="outlined"
              rows={2}
              multiline
              name={"message"}
              value={formik.values.message}
              onChange={formik.handleChange}
              autoComplete="off"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isLoading || !formik.isValid}
            onClick={() => formik.submitForm()}
          >
            Send
          </Button>
        </DialogActions>
        {isLoading && <LinearProgress></LinearProgress>}
      </Dialog>
    </React.Fragment>
  );
}
