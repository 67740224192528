//Packages Imports
import { useSelector } from "react-redux";
//App Import
import useAuthHandler from "../../../hooks/useAuthHandler";
import { utilityAPIs } from "../../../../api";
import {
  parseHelpStreamToBlockText,
  resetHistory,
  setHelpMessageStatus,
  selectHelpChatHistory,
  selectHelpChatStatus,
  setHelpMessageQueue,
} from "../../../../redux/utilities";
import { useAppDispatch } from "../../../../redux/store";

export function useSendAskHelpHandler() {
  const { token } = useAuthHandler();

  const dispatch = useAppDispatch();
  const status = useSelector((state) => selectHelpChatStatus(state));
  const history = useSelector((state) => selectHelpChatHistory(state));

  const onPostChat = (values) => {
    if (status === "idle" && token) {
      const postMessage = (params) => async (dispatch) => {
        await dispatch(
          setHelpMessageQueue({ isHuman: true, text: params.prompt })
        );
        return await dispatch(
          utilityAPIs.postAskHelp({ values: { prompt: params.prompt }, token })
        );
      };
      dispatch(postMessage({ prompt: values.prompt }));
    }
  };
  const onParseStreamToBlockText = (text) => {
    dispatch(parseHelpStreamToBlockText({ text }));
  };

  // derive status booleans for ease of use
  const isUninitialized = status === 'idle';
  const isLoading = status === "pending";
  const isError = status === "rejected";
  const isStreaming = status === "streaming";
  const isSuccess = status === "fulfilled";

  const onSetHelpMessageStatus = (status) =>
    dispatch(setHelpMessageStatus(status));

  const onResetHistory = () => dispatch(resetHistory());

  return {
    history,
    lastMessage: history.length ? history[history.length - 1] : null,
    onSetHelpMessageStatus,
    onPostChat,
    onParseStreamToBlockText,
    onResetHistory,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    isStreaming
  };
}
