//Packages Imports
import { useEffect } from "react";
import { useSelector } from "react-redux";
//App Import
import { useAppDispatch } from "../../../../../redux/store";
import useAuthHandler from "../../../../../shared/hooks/useAuthHandler";
import { sessionAPIs } from '../../../../../api'
import {
    selectData,
    selectStatus,
  } from "../../../../../redux/sessions";

export function useGetSessions(canGet) {
  const { token } = useAuthHandler();

  const dispatch = useAppDispatch();
  const status = useSelector((state) => selectStatus(state));
  const data = useSelector((state) => selectData(state));

  useEffect(() => {
    if (canGet && (status.get === undefined || status.get === 'refresh') && token) {
      dispatch(sessionAPIs.getSessions(token));
    }
  }, [status.get, token, dispatch, canGet]);

  // derive status booleans for ease of use
  const isUninitialized = status.get === undefined;
  const isLoading = status.get === "pending" || status.get === undefined;
  const isError = status.get === "rejected";
  const isSuccess = status.get === "fulfilled";

  return { data, isUninitialized, isLoading, isError, isSuccess };
}
