// DELETE SESSION
export const deleteSessionPending = (state, action) => {
    state.status.delete = "pending";
    state.processing.delete.id = action.meta.arg.id;
};
export const deleteSessionFulfilled = (state, action) => {
    state.status.delete = "fulfilled";
    state.data = action.payload;
};
export const deleteSessionRejected = (state, action) => {
    state.status.delete = "rejected";
}