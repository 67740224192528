export const CustomLogoutIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="20"
    viewBox="0 0 15 20"
    fill="none"
  >
    <g clipPath="url(#clip0_205_2146)">
      <path
        d="M7.93824 20H3.293C1.47747 20 -0.0125122 18.5688 -0.0125122 16.7981V3.20194C8.6613e-06 1.43117 1.47747 0 3.30552 0H7.95076C8.18866 0 8.38899 0.194057 8.38899 0.4245C8.38899 0.654942 8.18866 0.848999 7.95076 0.848999H3.30552C1.96579 0.848999 0.876469 1.90418 0.876469 3.20194V16.7981C0.876469 18.0958 1.96579 19.151 3.30552 19.151H7.95076C8.18866 19.151 8.38899 19.3451 8.38899 19.5755C8.38899 19.8059 8.18866 20 7.95076 20H7.93824Z"
        fill="white"
      />
      <path
        d="M13.9858 10.4184H4.69531C4.45741 10.4184 4.25708 10.2244 4.25708 9.99393C4.25708 9.76348 4.45741 9.56943 4.69531 9.56943H13.9858C14.2237 9.56943 14.424 9.76348 14.424 9.99393C14.424 10.2244 14.2237 10.4184 13.9858 10.4184Z"
        fill="white"
      />
      <path
        d="M10.2921 14.5543C10.1795 14.5543 10.0668 14.5179 9.97913 14.433C9.80383 14.2632 9.80383 13.9964 9.97913 13.8266L13.9357 9.99392L9.97913 6.1613C9.80383 5.9915 9.80383 5.72467 9.97913 5.55487C10.1544 5.38507 10.4299 5.38507 10.5926 5.55487L14.8623 9.69071C15.0376 9.86051 15.0376 10.1273 14.8623 10.2971L10.5926 14.433C10.505 14.5179 10.3923 14.5543 10.2796 14.5543H10.2921Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_205_2146">
        <rect width="15" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
