export const CustomHelpIcon = ()=>
<svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <g clipPath="url(#clip0_205_2150)">
            <path
              d="M10.0061 15.0212C9.77592 15.0212 9.5821 14.8274 9.5821 14.5972V13.834C9.5821 11.9443 11.6536 11.0357 11.7505 10.9994C13.1315 10.3452 14.0279 8.9158 14.0279 7.36523C14.0279 5.14839 12.2229 3.34342 10.0061 3.34342C8.3586 3.34342 6.89282 4.32465 6.27501 5.85099C6.19021 6.06904 5.93582 6.17807 5.71777 6.08116C5.49972 5.99636 5.3907 5.74197 5.48761 5.52392C6.23867 3.68261 8.0073 2.48334 10.0061 2.48334C12.6954 2.48334 14.8759 4.66383 14.8759 7.35311C14.8759 9.21865 13.7856 10.9509 12.0897 11.7504C12.0533 11.7626 10.4301 12.4773 10.4301 13.8098V14.573C10.4301 14.8031 10.2362 14.997 10.0061 14.997V15.0212Z"
              fill="white"
            />
            <path
              d="M10.006 17.5772C10.4141 17.5772 10.745 17.2464 10.745 16.8383C10.745 16.4302 10.4141 16.0993 10.006 16.0993C9.59793 16.0993 9.26709 16.4302 9.26709 16.8383C9.26709 17.2464 9.59793 17.5772 10.006 17.5772Z"
              fill="white"
            />
            <path
              d="M10.0061 20C4.48213 20 0 15.5179 0 10.0061C0 4.49425 4.48213 0 10.0061 0C15.53 0 20.0121 4.48213 20.0121 10.0061C20.0121 15.53 15.53 20.0121 10.0061 20.0121V20ZM10.0061 0.847971C4.95457 0.847971 0.847971 4.95457 0.847971 10.0061C0.847971 15.0575 4.95457 19.1641 10.0061 19.1641C15.0575 19.1641 19.1641 15.0575 19.1641 10.0061C19.1641 4.95457 15.0454 0.847971 10.0061 0.847971Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_205_2150">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>