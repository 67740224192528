import { useEffect } from "react";
import { Container, Stack, Box, useTheme } from "@mui/material";
// import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import useAuthHandler from "../../shared/hooks/useAuthHandler";
// import { Logo } from "../../shared/customIcons";
import { loginRequest } from "../../authConfig";

export default function AuthPage({ children }) {
  const isAuthenticated = useIsAuthenticated();
  const theme = useTheme();
  const { instance, accounts } = useMsal();
 //const [isRefresh , setIsRefresh] = useState(false)
  const {
    onSetToken,
    azureProfileStatusIsSuccess,
    accountStatusIsSuccess,
    accountStatusIsIdle,
    onAccountCheck,
  } = useAuthHandler();

  /*const refreshAuth = () => {
    sessionStorage.clear();
    setIsRefresh(true)
    const accessTokenRequest = {
      ...loginRequest,
      account: accounts[0],
    };
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        console.log(accessTokenResponse.accessToken);
        // Acquire token silent success
        onSetToken(accessTokenResponse);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup(accessTokenRequest)
            .then(function (accessTokenResponse) {
              onSetToken(accessTokenResponse);
            })
            .catch(function (error) {
              // Acquire token interactive failure
              console.log(error);
            });
        }
        console.log(error);
      });
  };*/

  /*useEffect(() => {
    // refresh the auth every 45 minutes,
    const refreshAuthInterval = setInterval(()=>{
      window.location.reload()
    }, 2 * 60 * 1000);
    //Clearing the interval
    return () => {
      clearInterval(refreshAuthInterval);
    };
  });*/

  useEffect(() => {
    if (isAuthenticated) {
      //setIsRefresh(false)
      const paramsAzureToken = {
        ...loginRequest,
        account: accounts[0],
      };
      setTimeout(() => {
        instance.acquireTokenSilent(paramsAzureToken).then((response) => {
          console.log(response)
          setTimeout(() => onSetToken(response), 2000);
        });
      }, 2000);
    }
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (azureProfileStatusIsSuccess && accountStatusIsIdle) {
      onAccountCheck();
    }
  }, [azureProfileStatusIsSuccess, accountStatusIsIdle, onAccountCheck]);
//console.log('isRefresh', isRefresh)
  if (!isAuthenticated) {
    return <SignInButton />;
  } else if ((azureProfileStatusIsSuccess && accountStatusIsSuccess) //|| isRefresh
) {
    return <>{children}</>;
  } else {
    return (
      <>
        <Container
          maxWidth=""
          sx={{
            height: "100vh",
            background: `linear-gradient(90deg, ${theme.palette.primary.main} 9.65%, ${theme.palette.secondary.main} 92.4%)`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          position={"absolute"}
          top={"45%"}
          left={"45%"}
        >
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Box>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "48.5%",
                  color:'white'
                }}
              >
               Loading 
              </Box>
              <Box
                elevation={0}
                sx={{
                  width: "100",
                  h: 100,
                  borderRadius: 100,
                  p: 30,
                  animation: "animateBg 3s linear infinite;",
                  backgroundSize: "300% 100%",
                  backgroundImage: `linear-gradient(179deg , #ffffff24, #ffffff00 ,#ffffff00)`,
                }}
              ></Box>
            </Box>
          </Stack>
        </Container>
      </>
    );
  }
}
