//Packages Imports]
import { useSelector } from "react-redux";

//App Import
import { useAppDispatch } from "../../../../../redux/store";
import useAuthHandler from "../../../../../shared/hooks/useAuthHandler";
import { sessionAPIs } from '../../../../../api'
import {
  selectData,
  selectStatus,
  selectDataInProcess,
  resetCurrentSession
} from "../../../../../redux/sessions";

export function useDeleteSessionHandler(id) {
  const { token } = useAuthHandler();

  const dispatch = useAppDispatch();
  const status = useSelector((state) => selectStatus(state));
  const data = useSelector((state) => selectData(state));

  const sessionOnProcessing = useSelector((state) =>
    selectDataInProcess(state)
  );

  const onDeleteSession = (id, value) => {
    if(status.postMessage === 'pending' || status.postMessage === 'fulfilled') return
    if (status.delete === "idle" && token) {
      dispatch(sessionAPIs.deleteSession({ token, id, value }));
      dispatch(resetCurrentSession())
    }
  };

  // derive status booleans for ease of use
  const isUninitialized = status.delete === "idle";
  const isLoading = status.delete === "pending";
  const isError = status.delete === "rejected";
  const isSuccess = status.delete === "fulfilled";

  return {
    data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onDeleteSession,
    processingId: sessionOnProcessing.delete.id,
  };
}
