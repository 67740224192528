// PIN SESSION
export const putPinSessionsPending = (state, action) => {
  state.status.pin = "pending";
  state.processing.pin.id = action.meta.arg.id;
};
export const putPinSessionsFulfilled = (state, action) => {
  state.status.pin = "fulfilled";
  state.data = action.payload;
};
export const putPinSessionsRejected = (state, action) => {
  state.status.pin = "rejected";
};
