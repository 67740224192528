// GET SESSIONS
export const getSessionsPending = (state, action) => {
  state.status.get = "pending";
};
export const getSessionsFulfilled = (state, action) => {
  state.status.get = "fulfilled";
  state.data = action.payload;
};
export const getSessionsRejected = (state, action) => {
  state.status.get = "rejected";
};
