import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Bubble } from "../chat/components/bubble";

export default function Summary({ openSessions, openFullChat,openChat }) {
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState(null);

  const handleClickOpen = (op) => {
    setOpen(true);
    setOption(op);
  };

  const handleClose = () => {
    setOpen(false);
    setOption(null);
  };

  const componentSize = useMemo(() => {
    if(openFullChat) return "0%"
    if (openSessions && openChat) return "24.7%";
    if (openSessions && !openChat) return "68%";
    if (!openSessions && openChat) return "43.4%";
    return "86.7%";
  }, [openSessions, openChat,openFullChat]);

  const style = {
    textDecoration: "underline",
    cursor: "pointer",
    transition: "0.2s",
    ":hover": {
      color: "#00e7ef",
      transition: "0.2s",
    },
  };
  const helperOptions = [
    {
      title: "Mention “Fujitsu” or “Deal Playbook” in your question",
      explanation:
        "This will ensure that the answers are sourced only from the Fujitsu source material and not from the model’s (publicly sourced) training data.",
      recommended: [
        {
          question:
            "What is Fujitsu’s position on providing unlimited indemnities?",
        },
        {
          question: "How does the Deal Playbook define indexation?",
        },
      ],
      noRecomended: [
        { question: "What is indexation?" },
        { question: "Can we provide unlimited indemnities?" },
      ],
    },
    {
      title:
        "Concise prompts (maximum 2-3 short sentences) generate better responses",
      explanation:
        "Keeping the prompt concise helps ensure that the underlying model understands the essential elements and avoids unnecessary distractions. It’s important to strike a balance between providing sufficient information and avoiding overwhelming the application. For the Fujitsu Legal AI Chatbot, if the prompt is much longer than a couple of sentences, it could lead to unclear or irrelevant responses. Where information is required about complex scenarios or multiple issues, you should use follow-up questions or multiple separate conversations (rather than a single prompt containing lengthy information and questions).",
      recommended: [
        {
          question: "Can Fujitsu provide Parent Company Guarantees?",
        },
        {
          question:
            "Can Fujitsu provide an IPR indemnity to the customer if the relevant Fujitsu supplier has not provided an IPR indemnity in its software licence to Fujitsu?",
        },
      ],
      noRecomended: [
        {
          question:
            "Fujitsu is negotiating a customer contract with a TCV of £10 million. The customer is insisting on a Parent Company Guarantee. Also, the customer's draft contract contains an IPR indemnity in respect of a software license being provided by Fujitsu to the customer. Fujitsu itself licences that software from another supplier, who does not provide an IPR indemnity in its license to Fujitsu. How should Fujitsu approach this contract negotiation?",
        },
      ],
    },
    {
      title:
        "Use follow-up questions to seek clarification or elaboration on the initial response",
      explanation:
        "This involves gradually refining and adjusting what you are asking, with the final response a result of iterative modification rather than achieving it in a single step. This process typically starts with broadening the scope of the problem, then gradually focusing on specific details and tuning the output. The Fujitsu Legal AI Chatbot is optimised for up to three follow-up questions. Any more follow-up questions may compromise the accuracy of the eventual response.",
      recommended: [
        {
          label: "Initial Question",
          question:
            "What are the most important issues for Fujitsu when negotiating a benchmarking provision in a customer contract?",
        },
        {
          label: "Follow-up Question",
          question:
            "Can Fujitsu accept benchmarking in contract with a two-year term?",
        },
      ],
    },
    {
      title:
        "On failure to receive expected response, ask alternative questions using different terminology",
      explanation:
        "If the expected answer is not received in the first attempt, or the chatbot responds that the source data does not contain the relevant information, you should try asking the question again using different/additional terminology. You could try using terms that are more likely to have been used in the Deal Playbook. If that does not work, the chatbot source (currently the Deal Playbook) may not in fact contain the information sought and you should explore other sources for your research.",
      recommended: [
        {
          label: "Initial Question",
          question:
            "If Fujitsu is a processor, what do I need in the contract?",
        },
        {
          label: "Alternative Question",
          question:
            "When Fujitsu is a data processor, what contractual terms have to be included in  the contract with the controller?",
        },
      ],
    },
    {
      title:
        "Avoid including original wording of entire contractual clause in your question",
      explanation:
        "If you would like to check Fujitsu’s risk positions in respect of a clause, it is not advisable to include the entire clause in the prompt. Instead, it would be better to summarise the clause briefly in plain English (without using the customer’s name). Alternatively, you could ask questions about different aspects of the clause. This is because an entire clause from a customer contract may contain customer data (e.g. name of customer) and may breach Fujitsu’s customer data restrictions. Also, it may contain defined terms and cross-references to other clauses which will make the question less likely to be understood by the chatbot",
      noRecomended: [
        {
          question: `Does this clause comply with Fujitsu's acceptable risk levels under the Deal Playbook: “The Supplier's aggregate liability in respect of loss of or damage to the Authority Premises or other property or assets of the Authority (including technical infrastructure, assets or equipment but excluding any loss or damage to the Authority's Data or any other data) that is caused by Defaults of the Supplier occurring in each and any Contract Year shall in no event exceed £10 million”?`,
        },
      ],
      recommended: [
        {
          question:
            "Can Fujitsu accept a cap of £10 million per year on its liability for damage to property? ",
        },
      ],
    },
  ];

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#134265",
          height: "100%",
          width: componentSize,
          position: "absolute",
          right:0,
          display:openFullChat?'none':'initial',
          px: 2,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            color: "white",
            textAlign: "left",
            mt: 6,
          }}
        >
          <br />
          <Typography variant="h5">Prompting Guidelines</Typography>
          <br />
          <Typography variant="body1">
            The specific techniques and principles set out below will help you
            achieve optimal results from the Fujitsu Legal AI Chatbot. Please click on the links below to know more.
          </Typography>
          <Typography variant="body1">
            <b>Note:</b> the current version of the chatbot provides responses
            based on the{" "}
            <Link
              target="_blank"
              sx={style}
              href={
                "https://fujitsu.service-now.com/asklegal?id=kb_article_view&sys_kb_id=df42421a1bcc3894dacc63d3b24bcb5b"
              }
            >
              Deal Playbook
            </Link>{" "}
            only.
          </Typography>
          <br />
          {helperOptions.map((op, idx) => (
            <Box key={idx}>
              <Typography
                variant="body1"
                sx={style}
                onClick={() => handleClickOpen(op)}
              >
                {op.title}
              </Typography>
              <br />
            </Box>
          ))}
        </Box>
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        onClose={handleClose}
        open={open}
        sx={{
          zIndex: 1000000,
        }}
      >
        <DialogTitle>{option?.title}</DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
            }}
          >
            {option?.explanation}
          </Typography>
          <br />
          <br />
          <br />
          {option?.noRecomended && (
            <Box
              sx={{
                borderBottom: "1px dashed #dedede",
                mb: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ErrorIcon sx={{ color: "red", mt: -0.5 }}></ErrorIcon>
                <Typography variant="h6">Not recommended</Typography>
              </Box>
              {option?.noRecomended.map((example, idx) => (
                <Box key={idx} position={"relative"}>
                  {example?.label}
                  <Bubble
                    disabled={true}
                    message={{ human_message: example.question }}
                  ></Bubble>
                </Box>
              ))}
            </Box>
          )}
          {option?.recommended && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  pb: 3,
                }}
              >
                <CheckBoxIcon sx={{ color: "green", mt: -0.5 }}></CheckBoxIcon>
                <Typography variant="h6">Recommended</Typography>
              </Box>
              {option?.recommended.map((example, idx) => (
                <Box key={idx} position={"relative"}>
                  {example?.label && (
                    <Box>
                      <Divider textAlign="left">{example?.label}</Divider>
                    </Box>
                  )}
                  <Bubble
                    disabled={false}
                    message={{ human_message: example.question }}
                  ></Bubble>
                </Box>
              ))}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
