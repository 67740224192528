import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getHeaders } from "../helpers/headers";
import { buildUrl } from "../helpers/url";
import { handleResponse } from "../helpers/handleResponse";
import routes from "../routes";

const getSessions = createAsyncThunk(
  "sessions/list",
  async (token, { rejectWithValue }) => {
    const response = await axios.get(
      buildUrl(routes.session.rootPath()),
      getHeaders(token)
    );
    return handleResponse(response, rejectWithValue)
  }
);

const createSession = createAsyncThunk(
  "sessions/create",
  async (data, { rejectWithValue }) => {
    const response = await axios.post(
      buildUrl(routes.session.rootPath()),
      {},
      getHeaders(data.token)
    );
    return handleResponse(response, rejectWithValue)
  }
);

const putSession = createAsyncThunk(
  "sessions/edit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        buildUrl(routes.session.editPath(data.id)),
        { ...data.values },
        getHeaders(data.token)
      );
      return handleResponse(response, rejectWithValue);
    } catch (error) {
    }
  }
);

const putPinSessions = createAsyncThunk(
  "sessions/pin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        buildUrl(routes.session.pinPath(data.id)),
        { pinned: data.value },
        getHeaders(data.token)
      );
      return handleResponse(response, rejectWithValue);
    } catch (error) {
    }
  }
);
const deleteSession = createAsyncThunk(
  "sessions/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        buildUrl(routes.session.deletePath(data.id)),
        getHeaders(data.token)
      );
      return handleResponse(response, rejectWithValue);
    } catch (error) {
    }
  }
);

const postMessageSession = createAsyncThunk(
  "sessions/chat",
  async (data, { rejectWithValue }) => {
    /*const response = await axios.post(
      buildUrl(routes.session.path, routes.session.chat(data.id)),
      {...data.values},
      getHeaders(data.token)
    );
    console.log( response)*/
    const response = await fetch(buildUrl(routes.session.chatPath(data.id)), {
      method: 'POST',
      ...getHeaders(data.token),
      body: JSON.stringify({...data.values}),
  });
  if ((response.status < 200 || response.status >= 300)) {
    console.log(response)
    return handleResponse(response, rejectWithValue);
  }

  // Handle the response as needed
  const reader = response.body.getReader();
    return reader
  }
);

const getChatMessageSession = createAsyncThunk(
  "sessions/chatHistory",
  async (data, { rejectWithValue }) => {
    const response = await axios.get(
      buildUrl(routes.session.chatPath(data.id)),
      getHeaders(data.token)
    );
    return handleResponse(response, rejectWithValue)
  }
);
const getCheckpointMessageHistory = createAsyncThunk(
  "sessions/checkpointMessageHistory",
  async (data, { rejectWithValue }) => {
    const response = await axios.get(
      buildUrl(routes.session.messageCheckpointPath(data.id,data.currentMessageId)),
      getHeaders(data.token)
    );
    return handleResponse(response, rejectWithValue)
  }
);

const downloadFile = createAsyncThunk(
  "sessions/downloadFile",
  async (data, { rejectWithValue }) => {
    const response = await axios.get(
      buildUrl(routes.session.downloadFilePath(data.id,data.file)),
     //{ headers: {Authorization: "Bearer " + data.token,}},
      {
        
        responseType: 'blob',

      },
    );
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue(response);
    }
    return response;
  }
);

const getSample = createAsyncThunk(
  "sessions/sample",
  async (data, { rejectWithValue }) => {
    const response = await axios.get(
      buildUrl(routes.session.samplePath(data.id,data.file)),
      getHeaders(data.token)
    );
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue(response);
    }
    return response.data;
  }
);


const session = {
  getSessions,
  createSession,
  putSession,
  putPinSessions,
  deleteSession,
  postMessageSession,
  getChatMessageSession,
  getCheckpointMessageHistory,
  downloadFile,
  getSample
};

export default session