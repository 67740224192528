const SESSION_PATH = "/api/session";
const AUTH_PATH = "/api/auth";
const UTILITY_PATH = "/api/utility";
const routes = {
  auth: {
    rootPath: () => AUTH_PATH,
  },
  session: {
    rootPath: () => SESSION_PATH,
    editPath: (sessionId) => `${SESSION_PATH}/${sessionId}`,
    pinPath: (sessionId) => `${SESSION_PATH}/${sessionId}/pin`,
    deletePath: (sessionId) => `${SESSION_PATH}/${sessionId}`,
    chatPath: (sessionId) => `${SESSION_PATH}/${sessionId}/chat`,
    messageCheckpointPath: (sessionId, messageId) =>
      `${SESSION_PATH}/${sessionId}/message/${messageId}/checkpoint_history`,
    downloadFilePath: (sessionId, file) =>
      `${SESSION_PATH}/${sessionId}/file/${file}`,
    samplePath: (sessionId, file) =>
      `${SESSION_PATH}/${sessionId}/sample/${file}`,
  },
  utilities: {
    sendEmail: () => `${UTILITY_PATH}/email`,
    postAskHelp: () => `/api/user_guide/ask`
  },
};
export default routes;
