import * as React from "react";
import { Box, List, ListItem, LinearProgress } from "@mui/material";
import { Bubble } from "./components/bubble";
import CustomDrawer from "../../../../shared/components/customDrawer";
import ChatBox from "./components/chatBox";
import { useGetChatHandler } from "./hooks/useGetChatHandler";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

// import useCurrentMessaheHandler from "../sessions/hooks/useCurrentMessageHandler";
// import useCurrentSessionHandler from "../sessions/hooks/useCurrentSessionHandler";
import { CustomMessagesIcon } from "../../../../shared/customIcons";

export default function Chat({
  openChat,
  openSessions,
  openFullChat,
  onOpenFullChat,
  handleDrawer,
}) {
  const { data, isLoading } = useGetChatHandler();
  // const messageHanlder = useCurrentMessaheHandler()
  // const currentSessionHandler = useCurrentSessionHandler()
  const messagesEndRef = React.useRef(null);

  const scrollToBottom = (auto) => {
    messagesEndRef.current?.scrollIntoView({
      behavior: auto ? "auto" : "smooth",
    });
  };

  const getSize = () => {
    if (openChat && openFullChat === false) {
      return "48% !important";
    }
    if (!openChat) {
      return "initial";
    }
    if (!openSessions && openFullChat) {
      return "93.3% !important";
    }
    if (openFullChat) {
      return "75% !important";
    }
  };

  const groupByCreatedDate = (array) => {
    return array.reduce((acc, message) => {
      const createdDate = new Date(message.created_date).toLocaleDateString();
      acc[createdDate] = acc[createdDate] || [];
      acc[createdDate].push(message);
      return acc;
    }, {});
  };
  const groupedMessages = groupByCreatedDate(data);

  React.useEffect(() => {
    scrollToBottom(true);
  }, [data]);

  return (
    <Box sx={{ display: "flex" }}>
      <CustomDrawer
        open={openChat}
        handleDrawer={handleDrawer}
        openFullChat={openFullChat}
        isChat={true}
        onOpenFullChat={onOpenFullChat}
        icon={<CustomMessagesIcon></CustomMessagesIcon>}
        drawerChevronColor="#000"
        style={{
          left: openSessions ? "24.9%" : "6.7%",
          width: getSize(),
        }}
        body={
          <>
            {isLoading ? (
              <LinearProgress></LinearProgress>
            ) : (
              <>
                <Box sx={{ height: "75.7%" }}>
                  <List sx={{ height: "90%", overflow: "auto", py: 5 }}>
                    {Object.keys(groupedMessages).map((createdDate) => (
                      <div key={createdDate}>
                        <Divider>
                          <Typography variant="overline" display="block">
                            {createdDate}
                          </Typography>
                        </Divider>
                        {groupedMessages[createdDate].map((message, index) => (
                          <div key={message.id}>
                            <ListItem disablePadding sx={{ display: "block" }}>
                              <Bubble
                                isLastItem={
                                  index ===
                                  groupedMessages[createdDate].length - 1
                                }
                                message={message}
                                scrollToBottom={scrollToBottom}
                              />
                            </ListItem>
                            {message.thread
                              ? message.thread.map((t) => (
                                  <ListItem
                                    key={t.id}
                                    disablePadding
                                    sx={{ display: "block" }}
                                  >
                                    <Bubble
                                      isLastItem={index === data.length - 1}
                                      message={t}
                                      scrollToBottom={scrollToBottom}
                                    />
                                  </ListItem>
                                ))
                              : ""}
                          </div>
                        ))}

                        <ListItem ref={messagesEndRef}></ListItem>
                      </div>
                    ))}
                  </List>
                </Box>
                {/**!messageHanlder.currentMessage && currentSessionHandler.currentSession &&  */}
                {
                  <ChatBox
                    openChat={openChat}
                    openSessions={openSessions}
                    openFullChat={openFullChat}
                  ></ChatBox>
                }
              </>
            )}
          </>
        }
      />
    </Box>
  );
}
