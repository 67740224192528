import {
  setAuth,
  selectData,
  selectStatus,
  selectAccountStatus,
  selectAccountData,
} from "../../redux/auth";
import { useDispatch, useSelector } from "react-redux";
import { accountAPIs, azureGraphAPI } from "../../api";
import {
  selectAzureProfileStatus,
  selectAzureProfilData,
} from "../../redux/auth";

export default function useAuthHandler() {
  const dispatch = useDispatch();
  const status = useSelector((state) => selectStatus(state));
  const data = useSelector((state) => selectData(state));
  const azureProfileStatus = useSelector((state) =>
    selectAzureProfileStatus(state)
  );
  const azureProfileData = useSelector((state) => selectAzureProfilData(state));
  const accountStatus = useSelector((state) => selectAccountStatus(state));
  const accountData = useSelector((state) => selectAccountData(state));
  const isSuccess = status === "fulfilled";
  const azureProfileStatusIsSuccess = azureProfileStatus === "fulfilled";
  const azureProfileStatusIsLoading = azureProfileStatus === "pending";
  const accountStatusIsSuccess = accountStatus === "fulfilled";
  const accountStatusIsIdle = accountStatus === "idle";
  const accountStatusIsLoading = accountStatus === "pending";

  const onSetToken = (response) => {
    dispatch(setAuth(response));
    dispatch(azureGraphAPI.getAzureUserProfile(response.accessToken));
  };

  const onAccountCheck = () => {
    dispatch(
      accountAPIs.postAccountCheck({ azureProfileData, token: data.accessToken })
    );
  };

  return {
    token: isSuccess ? data?.accessToken : "",
    azureProfileStatusIsSuccess,
    azureProfileStatusIsLoading,
    azureProfileData,
    accountStatusIsIdle,
    accountStatusIsSuccess,
    accountStatusIsLoading,
    accountData,
    onSetToken,
    onAccountCheck,
  };
}
