export const CustomPencilSquareIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
  >
    <g clipPath="url(#clip0_7_1776)">
      <path
        d="M14.8386 1.32675L13.6733 0.161437C13.458 -0.0538125 13.1089 -0.0538125 12.8936 0.161437L11.8239 1.23113L13.7687 3.17588L14.8384 2.10619C15.0536 1.89094 15.0536 1.542 14.8384 1.32675H14.8386Z"
        fill="white"
      />
      <path
        d="M11.3809 1.67421L6.17307 6.88205L8.11792 8.8269L13.3258 3.61906L11.3809 1.67421Z"
        fill="white"
      />
      <path
        d="M5.5603 9.43969L7.51837 9.11138L5.88862 7.48163L5.5603 9.43969Z"
        fill="white"
      />
      <path
        d="M13.0896 6.13762C12.7444 6.13762 12.4644 6.41756 12.4644 6.76275V13.3783C12.4644 13.5831 12.2977 13.7497 12.093 13.7497H1.62169C1.41694 13.7497 1.25025 13.5831 1.25025 13.3783V2.907C1.25025 2.70225 1.41694 2.53556 1.62169 2.53556H7.61944C7.96462 2.53556 8.24456 2.25562 8.24456 1.91043C8.24456 1.56525 7.96462 1.28531 7.61944 1.28531H1.62169C0.7275 1.28531 0 2.01262 0 2.907V13.3783C0 14.2727 0.7275 15 1.62169 15H12.093C12.9872 15 13.7147 14.2727 13.7147 13.3783V6.76275C13.7147 6.41756 13.4347 6.13762 13.0896 6.13762Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7_1776">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
