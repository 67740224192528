// CREATE SESSION
export const getAzureUserProfilePending = (state, action) => {
  state.azureProfile.status = "pending";
};

export const getAzureUserProfileFulfilled = (state, action) => {
  state.azureProfile.status = "fulfilled";
  state.azureProfile.data = action.payload;
};

export const getAzureUserProfileRejected = (state, action) => {
  console.log(state, action)
  state.azureProfile.status = "rejected";
};
