import { pusher } from "../../../../App";

const setCurrentSession = (state, action) => {
  if (state.currentSession?.id !== action.payload?.id) {
    state.chatHistory = [];
    state.recentMessageInfo = null;
    state.status.chatHistory = "idle";
    state.channel = pusher.subscribe(action.payload?.id);
  }

  state.currentSession = action.payload;
  state.currentMessage = null;
  state.status.sampleRecords = "idle";
  state.sampleRecords = [];
};

export default setCurrentSession;
