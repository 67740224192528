// CREATE SESSION
export const createSessionPending = (state, action) => {
  state.status.create = "pending";
  state.processing.create.id = "new";
};
export const createSessionFulfilled = (state, action) => {
  state.status.create = "fulfilled";
  state.data = action.payload;
};
export const createSessionRejected = (state, action) => {
  state.status.create = "rejected";
};
export const createSessionSettled = (state, action) => {
  state.processing.create.id = null;
}