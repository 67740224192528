// RESET STATUS IN FINALLY EVENTS
export const createSessionSettled = (state, action) => {
  //state.status.create = "idle";
  state.processing.create.id = null;
};
export const putSessionSettled = (state, action) => {
  state.processing.edit.id = null;
};
export const deleteSessionSettled = (state, action) => {
  state.status.delete = "idle";
  state.processing.delete.id = null;
};
export const putPinSessionsSettled = (state, action) => {
  state.status.pin = "idle";
  state.processing.pin.id = null;
};
export const getChatMessageSessionSettled = (state, action) => {
  //state.status.chatHistory = "idle";
  state.processing.chatHistory.id = null;
};
export const postMessageSessionSettled = (state, action) => {
 // state.status.postMessage = "idle";
  state.status.get = "refresh";
};
