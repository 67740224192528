// EDIT SESSION
export const putSessionPending = (state, action) => {
    state.status.edit = "pending";
    state.processing.edit.id = action.meta.arg.id;
};
export const putSessionFulfilled = (state, action) => {
    state.status.edit = "fulfilled";
    state.data = action.payload;
};
export const putSessionRejected = (state, action) => {
    state.status.edit = "rejected";
};
