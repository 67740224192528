import { Box, IconButton, CircularProgress,useTheme } from "@mui/material";
import React, { useEffect } from "react";
import CustomDrawer from "../../../../shared/components/customDrawer";
import Session from "./components/session";
import { useGetSessions } from "./hooks/useGetSessions";
import { useCreateSessionHandler } from "./hooks/useCreateSessionHandler";
import useCurrentMessaheHandler from "./hooks/useCurrentMessageHandler";
import useCurrentSessionHandler from "./hooks/useCurrentSessionHandler";
import { CustomHistoryIcon, CustomPencilSquareIcon } from "../../../../shared/customIcons";

export default function Sessions({ openSessions, handleDrawer }) {
  const createSessionHandler = useCreateSessionHandler()
  const {isLoading, isSuccess, data} = useGetSessions(true);
  const {setMessage, currentMessage} = useCurrentMessaheHandler()
  const { currentSession } = useCurrentSessionHandler();
  const theme = useTheme()

  useEffect(()=>{
    if(createSessionHandler.isSuccess){
      createSessionHandler.onResetStatus()
    }
  }, [createSessionHandler.isSuccess])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    if(isSuccess&& !currentMessage && !currentSession){
      setMessage(data[0],null)
    }
  },[isSuccess])// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomDrawer
      open={openSessions}
      icon={
        <Box ml={1.1} sx={{minHeight:'64px', display:'flex', alignItems:'center', justifyContent:'center'}}>
          <CustomHistoryIcon/>
        </Box>
      }
      drawerChevronColor="#fff"
      headerActions={
        <>
        {createSessionHandler.isLoading ? <CircularProgress size={15}/>:  <IconButton onClick={()=>{
           createSessionHandler.onResetStatus()
           createSessionHandler.onCreateSession()
        }} sx={{ color: "#fff" }}>
          <CustomPencilSquareIcon/>
        </IconButton>
        }
        </>
      }
      handleDrawer={handleDrawer}
      style={{
        width: openSessions ? "22.7% !important" : "initial",
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
       // boxShadow:'12px -10px 15px -11px rgba(0,0,0,0.75)',
        padding:'0px 8px 0px 8px'
      }}
      body={
        <Box px={2}>
          {data && data.length === 0 && isLoading && <CircularProgress>Loading Sessions</CircularProgress> }
          {data && data.map((session, index) => (
                <Session key={index} session={session}></Session>
              )) }
        </Box>
      }
    />
  );
}
