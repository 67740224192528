import { pusher } from "../../../../App";

const setCurrentMessage = (state, action) => {
  if (state.currentSession === undefined) {
    if(action.payload.session){
      state.channel = pusher.subscribe(action.payload.session.id);
    }
  }
  if (state.currentSession?.id !== action.payload?.session?.id) {
    state.chatHistory = [];
    state.status.chatHistory = "idle";
  }
  state.currentMessage = action.payload.message;
  state.currentSession = action.payload.session;
  state.status.sampleRecords = "idle";
  state.sampleRecords = [];
  state.chatHistory = [];
  state.status.chatHistory = "idle";
};

export default setCurrentMessage;