import axios from "axios";
import routes from "../routes";
import { buildUrl } from "../helpers/url";
import { getHeaders } from "../helpers/headers";
import { handleResponse } from "../helpers/handleResponse";
import { createAsyncThunk } from "@reduxjs/toolkit";

const postSendEmail = createAsyncThunk(
  "utility/sendEmail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        buildUrl(routes.utilities.sendEmail()),
        { ...data },
        getHeaders(data.token)
      );
      return handleResponse(response, rejectWithValue);
    } catch (error) {}
  }
);

const postAskHelp = createAsyncThunk(
  "utility/postAskHelp",
  async (data, { rejectWithValue }) => {
    const response = await fetch(buildUrl(routes.utilities.postAskHelp()), {
      method: 'POST',
      ...getHeaders(data.token),
      body: JSON.stringify(data.values),
  });

  if ((response.status < 200 || response.status >= 300)) {
    console.log(response)
    return handleResponse(response, rejectWithValue);
  }

  // Handle the response as needed
  const reader = response.body.getReader();
    return reader
  }
);



const utilities = {
  postSendEmail,
  postAskHelp
};

export default utilities;
