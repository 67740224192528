import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { LinearProgress, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomFeedbackIcon } from "../../customIcons";
import useAuthHandler from "../../hooks/useAuthHandler";
import { useSendEmailHandler } from "./hooks/useFeedbackHandler";

export default function FeedbackModal({ buttonComponent }) {
  const [open, setOpen] = React.useState(false);
  const { azureProfileData } = useAuthHandler();
  const { isLoading, isSuccess, resetStatus, onSendFeedbackEmail } =
    useSendEmailHandler();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: azureProfileData?.displayName,
      newFeature: "",
      improvement: "",
      checkpoint: "",
      emailType: "feedback",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      if (
        formik.values.newFeature !== ""  ||
        formik.values.improvement !== ""
      ) {
        onSendFeedbackEmail({ ...values });
      }
    },
  });
  const handleClose = React.useCallback(() => {
    formik.resetForm();
    resetStatus();
    setOpen(false);
  }, [formik, resetStatus]);

  React.useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess, handleClose]);

  const buttonWithOnClick = React.cloneElement(buttonComponent, {
    onClick: handleClickOpen,
  });
  return (
    <React.Fragment>
      {buttonWithOnClick}
      <Dialog
        open={open}
        fullWidth
        keepMounted
        onClose={handleClose}
        sx={{ zIndex: 10000 }}
      >
        <DialogContent>
          <DialogTitle>
            <CustomFeedbackIcon isBlack={true} /> {"Feedback"}
          </DialogTitle>
          <Stack spacing={5}>
            <TextField
              label="Name"
              variant="outlined"
              name={"name"}
              value={formik.values.name}
              onChange={formik.handleChange}
              autoComplete="off"
            />
            <TextField
              label="Features I like…"
              variant="outlined"
              rows={2}
              multiline
              name={"newFeature"}
              value={formik.values.newFeature}
              onChange={formik.handleChange}
              autoComplete="off"
            />
            <TextField
              label="One thing that I would improve…"
              variant="outlined"
              rows={2}
              multiline
              name={"improvement"}
              value={formik.values.improvement}
              onChange={formik.handleChange}
              autoComplete="off"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={
              isLoading || !(formik.values.newFeature !== "" || formik.values.improvement !== "")
            }
            onClick={formik.handleSubmit}
          >
            Send
          </Button>
        </DialogActions>
        {isLoading && <LinearProgress></LinearProgress>}
      </Dialog>
    </React.Fragment>
  );
}
