import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Button } from "@mui/material";
import {
  Container,
  Stack,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
// import { Logo } from "../../shared/customIcons";
/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package
 */

export const SignInButton = () => {
  const { instance } = useMsal();
  const theme = useTheme();
  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };
  return (
    <>
      <Container
        maxWidth=""
        sx={{
          height: "100vh",
          background: `linear-gradient(90deg, ${theme.palette.primary.main} 9.65%, ${theme.palette.secondary.main} 92.4%)`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        position={"absolute"}
        top={"45%"}
        left={"45%"}
      >
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Box>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "48%",
              }}
            >

              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                >
                <Box sx={{
                  //borderBottom:'1px solid white',
                  pb:1,
                  width:'100%'
                }}>
                 
                </Box>
                <Typography variant="h6" fontWeight={'bold'} sx={{pt:1, mb:20}} color={'white'}>
                  Legal Chat</Typography>
                <Button
                  color={"primary"}
                  variant="contained"
                  onClick={() => handleLogin("popup")}
                >
                  Login
                </Button>
              </Stack>
            </Box>
            <Box
              elevation={0}
              sx={{
                width: "100",
                h: 100,
                borderRadius: 100,
                p: 30,
                backgroundSize: "300% 100%",
                backgroundImage: `linear-gradient(179deg , #ffffff24, #ffffff00 ,#ffffff00)`,
              }}
            ></Box>
          </Box>
        </Stack>
      </Container>
    </>
  );
};
