import {
  Box,
  Grid,
  IconButton,
  InputBase,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usePostChatHandler } from "../hooks/usePostChatHandler";
  
export default function ChatBox({openChat,
  openSessions,
  openFullChat}) {
  const {onPostChat, isLoading, isSuccess} = usePostChatHandler()

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if(isLoading||isSuccess) return
      formik.submitForm()
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { prompt: "" },
    validationSchema: Yup.object({
      prompt: Yup.string().required("Required"),
    }),
    onSubmit: (values, { resetForm }) => { 
      onPostChat(values);
      resetForm()
    },
  });
  const getSize = () => {
    if (openChat && openFullChat === false) {
      return "47% !important";
    }
    if (!openChat) {
      return "initial";
    }
    if(!openSessions && openFullChat){
      return "92.3% !important";
    }
    if (openFullChat) {
      return "74% !important";
    }
  };
  return (
    <Box sx={{
      position:'fixed',
      bottom:"0px",
      width:getSize(),
      backgroundColor:'white'
    }}>

   
      <Grid container>
        <Grid item={true} xs={11}>
          <Box sx={{ p: 3 }}>
            <InputBase
              sx={{
                backgroundColor: "#F0F0F0",
                border: "none !important",
                borderRadius: 2,
                p: "15px 15px ",
              }}
              inputProps={{
                sx: {
                  border: "none !important",
                },
              }}
              autoComplete={"off"}
              name={"prompt"}
              value={formik.values.prompt}
              onChange={formik.handleChange}
              onKeyDown={handleKeyDown}
              fullWidth
              id="outlined-multiline-static"
              placeholder="What can I help you with today?"
            />
          </Box>
        </Grid>
        <Grid item={true} xs={1}>
          <Box sx={{ py: 4.2, pr:12}}>
            <IconButton
              size="large"
              onClick={() => formik.submitForm()}
              aria-label="delete"
              disabled={isLoading||isSuccess}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.55211 1.74443L2.34343 6.68362H7.22598C7.64133 6.68362 7.97804 7.04912 7.97804 7.5C7.97804 7.95087 7.64133 8.31638 7.22598 8.31638H2.34343L1.55211 13.2555L13.9237 7.5L1.55211 1.74443ZM0.947519 7.5L0.0199061 1.71005C-0.0550839 1.24198 0.08444 0.763575 0.393917 0.427629C0.782767 0.00552781 1.36919 -0.117071 1.87464 0.118079L15.3138 6.37031C15.7301 6.56399 16 7.00834 16 7.5C16 7.99166 15.7301 8.43602 15.3138 8.6297L1.87464 14.8819C1.36919 15.1171 0.782767 14.9944 0.393917 14.5724C0.08444 14.2364 -0.0550841 13.758 0.0199061 13.2899L0.947519 7.5Z"
                  fill={(isLoading||isSuccess) ? "#e8e8e8":"black"}
                />
              </svg>
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      </Box>
  );
}
