import "./App.css";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Main from "./pages/private/main";
import AuthPage from "./pages/public/auth";
import { ButtonGroup, Stack } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Provider } from "react-redux";
import { store } from "./redux/store";
import Pusher from "pusher-js";
import {
  CustomFeedbackIcon,
  CustomHelpIcon,
  CustomLogoutIcon,
  Logo,
} from "./shared/customIcons";
import { useMsal } from "@azure/msal-react";
import FeedbackModal from "./shared/layouts/EmailModalsLayout/FeedbackModal";
import TechnicalSupportModal from "./shared/layouts/EmailModalsLayout/TechnicalSupportModal";
import EngineeringIcon from '@mui/icons-material/Engineering';
import HelpModal from "./shared/layouts/HelpLayout/HelpModal";

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_JS_API_KEY, {
  cluster: process.env.REACT_APP_PUSHER_JS_CLUSTER,
  useTLS: true,
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#FE0103",
      contrastText: "#ffff",
    },
    secondary: {
      main: "#D80183",
      dark: "#242424",
      light: "#373737",
      contrastText: "#D1B396",
      contrastTextTransparent: "#d1b3966e",
    },
    third:{
      main:"#0f4761"
    },
    info: {
      main: "#1d1d1d",
      light: "#E8E8E8",
      contrastTextLight: "#fff",
      contrastText: "#A4A4A4",
    },
  },
});
function App() {
  //const {onSetToken} = useAuthHandler()
  console.log(process.env );
  
  const { instance } = useMsal();

  const navItems = [
    {
      trigger: (i) => (
        <TechnicalSupportModal
          key={i}
          buttonComponent={
            <Button
              sx={{
                borderColor: "#fff !important",
                color: "#fff",
                borderRadius: 0,
                height: "50px",
                width: "12rem",
              }}
            >
              <EngineeringIcon />{" "}
              <Typography mx={1} textTransform={"capitalize"}>
                Technical Support
              </Typography>
            </Button>
          }
        />
      ),
    },
    {
      trigger: (i) => (
        <FeedbackModal
          key={i}
          buttonComponent={
            <Button
              sx={{
                borderColor: "#fff !important",
                color: "#fff",
                borderRadius: 0,
                height: "50px",
                width: "10.5rem",
              }}
            >
              <CustomFeedbackIcon />{" "}
              <Typography mx={1} textTransform={"capitalize"}>
                Feedback
              </Typography>
            </Button>
          }
        />
      ),
    },
    {
      trigger: (i) => (
        <HelpModal
          key={i}
          buttonComponent={
            <Button
              sx={{
                borderColor: "#fff !important",
                color: "#fff",
                borderRadius: 0,
                height: "50px",
                width: "10.5rem",
              }}
            >
             <CustomHelpIcon />{" "}
              <Typography mx={1} textTransform={"capitalize"}>
              User Guide
              </Typography>
            </Button>
          }
        />
      ),
    },
    {
      trigger: (i) => (
        <Button
          key={i}
          onClick={() => {
            instance
              .logoutRedirect({
                postLogoutRedirectUri:
                  process.env.REACT_APP_AZURE_ENTRAID_REDIRECT_URI,
              })
              .then(() => {
                window.location.replace(
                  process.env.REACT_APP_AZURE_ENTRAID_REDIRECT_URI
                );
              });
          }}
          sx={{
            borderColor: "#fff !important",
            color: "#fff",
            borderRadius: 0,
            height: "50px",
            width: "10.5rem",
          }}
        >
          <CustomLogoutIcon />{" "}
          <Typography mx={1} textTransform={"capitalize"}>
            log out
          </Typography>
        </Button>
      ),
    },
  ];
  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <div className="App">
            <AuthPage>
              <AppBar
                component="nav"
                sx={{
                  zIndex: 900,
                  boxShadow:"none",
                  background: `linear-gradient(90deg, ${theme.palette.primary.main} 9.65%, ${theme.palette.secondary.main} 92.4%)`,
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  height={"50px"}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      ml={'19.8px'}
                      pr={'5px'}
                      mb={"3px"}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Logo></Logo>
                    </Box>
                    <Box
                      sx={{
                        borderLeft: "1px solid ",
                        mx: 1.75,
                        height: "50px",
                      }}
                    />
                    <Box
                      mr={1.75}
                      mb={"3px"}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "1.16rem",
                        paddingTop: "3px",
                      }}
                    >
                      Legal Sensei
                    </Box>
                  </Box>
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <ButtonGroup variant="text" aria-label="text button group">
                      {navItems.map((item, i) => item.trigger(i))}
                    </ButtonGroup>
                  </Box>
                </Stack>
              </AppBar>
              <Main></Main>
            </AuthPage>
          </div>
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;
