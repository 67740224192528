import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  Tooltip,
  useTheme,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import NameSession from "./nameSession";
import useCurrentSessionHandler from "../hooks/useCurrentSessionHandler";
import { usePinSessionHandler } from "../hooks/usePinSessionHandler";
import { useDeleteSessionHandler } from "../hooks/useDeleteSession";
import useCurrentMessaheHandler from "../hooks/useCurrentMessageHandler";
import { useGetSessions } from "../hooks/useGetSessions";
import { CustomPinIcon } from "../../../../../shared/customIcons";

export default function Session({ session }) {
  const { setSession, currentSession, isStreamingMessage} = useCurrentSessionHandler();
  const { setMessage, currentMessage } = useCurrentMessaheHandler();
  const { onPinSession, isLoading, isSuccess, processingId } =
    usePinSessionHandler();
  const deleteSessionHandler = useDeleteSessionHandler();
  const getSessionHandler = useGetSessions(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [action, setAction] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme()

  const options = useMemo(
    () => [
      {
        label: session && session.pinned ? "Unpin Session" : "Pin Session",
        disabled: false,
      },
      { label: "Delete Session", disabled: false },
      { label: "Share Session", disabled: true },
      { label: "Download Session", disabled: true },
    ],
    [session]
  );

  useEffect(() => {
    if (action === "Pin Session" || action === "Unpin Session") {
      onPinSession(session.id, !session.pinned);
    }
    if (action === "Delete Session") {
      deleteSessionHandler.onDeleteSession(session.id);
    }
  }, [action]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (action) {
      setAction(null);
    }
  }, [isSuccess, action, deleteSessionHandler.isSuccess]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    if (e.target.outerText) {
      setAction(e.target.outerText);
    }
    setAnchorEl(null);
  };
  const messageContainer = (m, i) => (
    <Box key={i}>
      <Tooltip arrow placement="right" title={m.human_message}>
      <Box
        onClick={() => setMessage(session, m)}
        sx={{
          fontSize: "15px",
          color: currentMessage?.id === m.id ? theme.palette.secondary.contrastText : theme.palette.info.contrastTextLight,
          background: currentMessage?.id === m.id ? theme.palette.secondary.light : "initial",
          textAlign: "left",
          textOverflow: "ellipsis",
          overflow: "hidden",
          my: 0.2,
          whiteSpace: "nowrap",
          cursor: "pointer",
          "&:hover": {
            color: theme.palette.secondary.contrastText,
            background: theme.palette.secondary.light,
          },
        }}
        p={1}
        borderRadius={2}
      >
        {m.human_message}
      </Box>
      </Tooltip>
    </Box>
  );

  const sessionContainer = (
    <>
      <Box
        sx={{
          cursor: "pointer",
          pl: 1,
          transition: "0.3s",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box onClick={() => setSession(session)}>
            <NameSession
              session={session}
              action={action}
              canEdit={session?.id === currentSession?.id && !isStreamingMessage}
              handleActionCallback={(value) => setAction(value)}
            />
          </Box>
            {session?.id !== currentSession?.id && session.pinned && <CustomPinIcon />}
          {session?.id === currentSession?.id && <>
            <Box>
            {processingId === session.id && isLoading ? (
              <CircularProgress
                size={20}
                color="primary"
                variant="indeterminate"
              />
            ) : (
              <>
                {session.pinned && <CustomPinIcon />}

                <IconButton
                  sx={{ color: theme.palette.info.contrastTextLight}}
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={(e) => handleClick(e, session)}
                >
                  <MoreHoriz />
                </IconButton>
              </>
            )}
          </Box>
          </>}
        </Stack>
      </Box>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option, idx) => (
          <MenuItem
            key={idx}
            selected={option === "Pyxis"}
            onClick={handleClose}
            disabled={option.disabled || isStreamingMessage}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );

  return (
    <div>
      {deleteSessionHandler.isLoading &&
      deleteSessionHandler.processingId === session.id ? (
        <LinearProgress sx={{ my: 1 }}></LinearProgress>
      ) : (
        <Box
          sx={{
            border:'1px solid #00000000',
            borderLeft: `1px solid ${
              currentSession?.id === session.id ? "#d1b39678" : "#00000000"
            } `,
            mb: 10,
            p: 1,
            px:2,
            pb:3,
            borderRadius:'10px',
            boxShadow:'4px 2px 4px 0px #0000004f',
            transition:'0.3s',
            "&:hover": {
              transition:'0.3s',
              borderLeft: `1px solid ${theme.palette.secondary.contrastText}`,
            },
            background: '#0000001c',
          }}
        >
          {sessionContainer}

          <Box sx={{ height: "10px" }}>
            {currentSession &&
              getSessionHandler.isLoading &&
              session?.id === currentSession?.id && (
                <Skeleton
                  animation={"pulse"}
                  variant="text"
                  sx={{
                    borderRadius: 2.4,
                    bgcolor: theme.palette.secondary.contrastTextTransparent,
                    opacity:.1,
                    fontSize: "2.78rem",
                  }}
                />
              )}
          </Box>
          <Box
            onClick={() => setMessage(session, null)}
            sx={{
              fontSize: "15px",
              color:
                !currentMessage && currentSession?.id === session.id
                  ? theme.palette.secondary.contrastText
                  : theme.palette.info.contrastTextLight,
              background:
                !currentMessage && currentSession?.id === session.id
                  ? theme.palette.secondary.light
                  : "initial",
              textAlign: "left",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              mb: 1,
              cursor: "pointer",
              "&:hover": {
                color: theme.palette.secondary.contrastText,
                background: theme.palette.secondary.light,
              },
            }}
            p={1}
            borderRadius={2}
          >
            Full conversation
          </Box>
          <Typography
            sx={{
              color: theme.palette.info.contrastText,
              cursor: "default",
            }}
            variant="overline"
            cursor={""}
            fontSize={"10px"}
            pl={1}
            display="block"
            textAlign={"left"}
          >
            Conversation Segments
          </Typography>
          {session.messages &&
            session.messages.map((m, i) => (
              <div key={m.id}>
                {messageContainer(m, i)}
                {m.thread
                  ? m.map((subm, subi) => messageContainer(subm, subi))
                  : ""}
              </div>
            ))}
        </Box>
      )}
    </div>
  );
}
