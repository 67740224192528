import { utilityAPIs } from "../../../api";
import {
  postSendEmailFulfilled,
  postSendEmailPending,
  postSendEmailRejected,
} from "./cases/senEmail";

import {
  postHelpMessagePending,
  postHelpMessageFulfilled,
  postHelpMessageRejected
} from "./cases/postHelpMessage";

export const extraReducers = (builder) => {
  builder.addCase(
    utilityAPIs.postSendEmail.pending,
    postSendEmailPending
  );

  builder.addCase(
    utilityAPIs.postSendEmail.fulfilled,
    postSendEmailFulfilled
  );

  builder.addCase(
    utilityAPIs.postSendEmail.rejected,
    postSendEmailRejected
  );

  builder.addCase(
    utilityAPIs.postAskHelp.pending,
    postHelpMessagePending
  );

  builder.addCase(
    utilityAPIs.postAskHelp.fulfilled,
    postHelpMessageFulfilled
  );

  builder.addCase(
    utilityAPIs.postAskHelp.rejected,
    postHelpMessageRejected
  );
};
