import { createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./extraReducers";
import reducers from "./reducers";

export const utilitySlice = createSlice({
  name: "utility",
  initialState: {
    email: {
      status: "idle",
      data: null,
    },
    helpMessages: {
      status: "idle",
      history: [],
    },
    snackbar: {
      open: false,
      vertical: "bottom",
      horizontal: "center",
      text: "",
      duration: 4000,
      type:'success'
    },
  },
  reducers,
  extraReducers,
});

export default utilitySlice;
