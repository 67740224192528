const resetCurrentSession = (state, action) => {
  state.currentSession = undefined;
  state.currentMessage = undefined;
  state.chatHistory = [];
  state.recentMessageInfo = null;
  state.status.chatHistory = "idle";
  state.sampleRecords = [];
};

export default resetCurrentSession;
