import authSlice from './slice'

// SELECTORS
export const selectData = (state) => state?.auth?.data;
export const selectStatus = (state) => state?.auth?.status;

export const selectAzureProfilData = (state) => state?.auth?.azureProfile?.data;
export const selectAzureProfileStatus = (state) => state?.auth?.azureProfile?.status;


export const selectAccountData = (state) => state?.auth?.account?.data;
export const selectAccountStatus = (state) => state?.auth?.account?.status;

// ACTIONS
export const { setAuth } = authSlice.actions;

// REDUCER
export default authSlice.reducer