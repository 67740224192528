export const postSendEmailPending = (state, action) => {
  state.email.status = "pending";
};

export const postSendEmailFulfilled = (state, action) => {
  state.email.status = "fulfilled";
  state.email.data = action.payload;
};

export const postSendEmailRejected = (state, action) => {
  state.email.status = "rejected";
};
