// SEND MEESAGE
export const postHelpMessagePending = (state, action) => {
  state.helpMessages.status = "pending";
};
export const postHelpMessageFulfilled = (state, action) => {
  state.helpMessages.status = "streaming";
  state.helpMessages.history.push({
    isStream: true,
    isIA:true,
    text: action.payload});
};
export const postHelpMessageRejected = (state, action) => {
  state.helpMessages.status = "idle";
};
