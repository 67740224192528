import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getProfile = createAsyncThunk(
  "user/profile",
  async (token, { rejectWithValue }) => {
    const response = await axios.get(`${process.env.REACT_APP_API}/api/auth/profile`, {
      headers:{'Authorization': "Bearer " + token,
      "Content-Type": "application/json",
      'Accept': "application/json"},
    });
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue(response);
    }
    return response;
  }
);
export const createProfile = createAsyncThunk(
  "user/createProfile",
  async (data, { rejectWithValue }) => {
    const response = axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/api/auth/profile`,
      data: {...data.values},
      headers:{
          'Authorization': 'Bearer '+data.token
      }})
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue(response);
    }
    return response;
  })


export const userSlice = createSlice({
  name: "user",
  initialState: {
    data: {},
    status: undefined,
    createStatus:undefined
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state, action) => {
      state.status = "pending";
    });

    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.status = "fulfilled";
      state.data = action.payload;
    });

    builder.addCase(getProfile.rejected, (state, action) => {
      state.status = "rejected";
    });
    builder.addCase(createProfile.pending, (state, action) => {
      state.createStatus = "pending";
    });

    builder.addCase(createProfile.fulfilled, (state, action) => {
      state.createStatus = "fulfilled";
      state.data = action.payload;
    });

    builder.addCase(createProfile.rejected, (state, action) => {
      state.createStatus = "rejected";
    });
  },
});

export const selectStatus = (state) => state.user.status;
export const selectCreateStatus = (state) => state.user.createStatus;
export const selectData = (state) => state.user.data;

export default userSlice.reducer;
