//Packages Imports
import { useSelector } from "react-redux";
//App Import
import { useAppDispatch } from "../../../../redux/store";
import useAuthHandler from "../../../hooks/useAuthHandler";
import { utilityAPIs } from "../../../../api";
import { resetEmailStatus, selectEmailStatus } from "../../../../redux/utilities";

export function useSendEmailHandler() {
  const { token } = useAuthHandler();

  const dispatch = useAppDispatch();
  const status = useSelector((state) => selectEmailStatus(state));

  const onSendFeedbackEmail = (values) => {
    console.log('asDASDA')
    dispatch(utilityAPIs.postSendEmail({ ...values, token }));
  };
  const onSendTechnicalSupportEmail = (values) => {
    dispatch(utilityAPIs.postSendEmail({ ...values, token }));
  };
  const resetStatus = () => dispatch(resetEmailStatus());
  // derive status booleans for ease of use
  const isLoading = status === "pending" || status === undefined;
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  return { status, resetStatus, onSendFeedbackEmail,onSendTechnicalSupportEmail, isLoading, isError, isSuccess };
}
