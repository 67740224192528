export const CustomFeedbackIcon = ({isBlack}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="20"
    viewBox="0 0 12 20"
    fill="none"
  >
    <g clipPath="url(#clip0_205_2123)">
      <path
        d="M4.00396 20C3.80198 20 3.64752 19.8419 3.64752 19.6353V17.1915C3.64752 17.0942 3.68317 16.997 3.75445 16.9362C3.82574 16.8632 3.90891 16.8268 4.00396 16.8268H11.097C11.299 16.8268 11.4535 16.9848 11.4535 17.1793V19.6231C11.4535 19.7204 11.4178 19.8176 11.3465 19.8784C11.2752 19.9514 11.1921 19.9878 11.097 19.9878H4.00396V20ZM4.36039 17.5562V19.2705H6.84356H10.7525V17.5562H4.34851H4.36039Z"
        fill={isBlack?"black":"white"}
      />
      <path
        d="M2.86339 10.8328C2.66141 10.8328 2.50696 10.6748 2.50696 10.4681V2.67477C2.50696 1.86018 3.14854 1.20364 3.95646 1.20364C4.34854 1.20364 4.70498 1.34954 4.97825 1.62918C5.25151 1.90881 5.40597 2.27355 5.40597 2.67477V7.36778C5.40597 7.57446 5.25151 7.73252 5.04953 7.73252C4.84755 7.73252 4.6931 7.57446 4.6931 7.36778V2.67477C4.6931 2.48024 4.60993 2.28571 4.47924 2.15197C4.33666 2.00608 4.14656 1.93313 3.95646 1.93313C3.5525 1.93313 3.21983 2.26139 3.21983 2.67477V10.4681C3.21983 10.6748 3.06537 10.8328 2.86339 10.8328Z"
        fill={isBlack?"black":"white"}
      />
      <path
        d="M4.57428 17.5319C4.43171 17.5319 4.28913 17.4346 4.24161 17.2887L3.62379 15.4407C3.52874 15.1489 3.39804 14.8815 3.21983 14.6504C2.93468 14.2735 2.47131 13.7143 1.81785 13.0091C1.42577 12.5836 1.12874 12.0973 0.926757 11.5745L0.106955 9.50759C-0.0356193 9.14285 -0.0356193 8.75379 0.118836 8.40121C0.26141 8.04862 0.534678 7.78115 0.879232 7.64741C1.54458 7.37993 2.31686 7.69604 2.62577 8.37689C2.70894 8.55926 2.62577 8.77811 2.44755 8.86321C2.26933 8.94832 2.05547 8.86321 1.9723 8.68084C1.81785 8.35258 1.44953 8.19452 1.12874 8.32826C0.9624 8.40121 0.831707 8.52279 0.76042 8.693C0.689133 8.86321 0.689133 9.05774 0.76042 9.24011L1.58022 11.3191C1.75844 11.769 2.00795 12.1702 2.34062 12.5349C3.01785 13.2766 3.48121 13.8237 3.79012 14.2371C4.00398 14.5289 4.1822 14.8571 4.30101 15.2219L4.91884 17.0699C4.97824 17.2644 4.88319 17.4711 4.69309 17.5319C4.65745 17.5441 4.60992 17.5562 4.57428 17.5562V17.5319Z"
        fill={isBlack?"black":"white"}
      />
      <path
        d="M11.0257 16.5714C11.0257 16.5714 10.9544 16.5714 10.9307 16.5593C10.7406 16.4985 10.6336 16.304 10.6931 16.1094L11.1327 14.5532C11.2396 14.1277 11.299 13.69 11.299 13.2523V5.17935C11.299 4.98482 11.2158 4.79029 11.0851 4.65655C10.9426 4.51065 10.7643 4.4377 10.5624 4.4377C10.1584 4.4377 9.82573 4.77813 9.82573 5.17935V9.36172C9.82573 9.5684 9.67127 9.72646 9.46929 9.72646C9.26731 9.72646 9.11285 9.5684 9.11285 9.36172V5.17935C9.11285 4.36476 9.76632 3.70822 10.5624 3.70822C10.9544 3.70822 11.3109 3.85412 11.5841 4.13375C11.8574 4.41339 12.0119 4.77813 12.0119 5.17935V13.2523C12.0119 13.7508 11.9525 14.2614 11.8218 14.7477L11.3822 16.3161C11.3346 16.4742 11.1921 16.5836 11.0376 16.5836L11.0257 16.5714Z"
        fill={isBlack?"black":"white"}
      />
      <path
        d="M7.24755 9.61703C7.04557 9.61703 6.89111 9.45898 6.89111 9.25229V3.18542C6.89111 2.37083 7.54458 1.71429 8.34062 1.71429C8.7327 1.71429 9.08913 1.86019 9.3624 2.13983C9.63567 2.41946 9.79012 2.7842 9.79012 3.18542V5.66566C9.79012 5.87235 9.63567 6.0304 9.43369 6.0304C9.23171 6.0304 9.07725 5.87235 9.07725 5.66566V3.18542C9.07725 2.99089 8.99408 2.79636 8.86339 2.66262C8.72082 2.51673 8.5426 2.44378 8.34062 2.44378C7.93666 2.44378 7.60398 2.77205 7.60398 3.18542V9.25229C7.60398 9.45898 7.44953 9.61703 7.24755 9.61703Z"
        fill={isBlack?"black":"white"}
      />
      <path
        d="M5.04949 9.61702C4.84751 9.61702 4.69305 9.45897 4.69305 9.25228V1.47112C4.69305 1.08207 4.83563 0.705167 5.12078 0.425532C5.39404 0.145897 5.76236 0 6.14256 0C6.9386 0 7.59206 0.656535 7.59206 1.47112V6.3465C7.59206 6.55319 7.43761 6.71125 7.23563 6.71125C7.03365 6.71125 6.87919 6.55319 6.87919 6.3465V1.47112C6.87919 1.05775 6.54652 0.729483 6.13068 0.729483C5.9287 0.729483 5.75048 0.802432 5.60791 0.948328C5.46533 1.09422 5.39404 1.2766 5.39404 1.47112V9.25228C5.39404 9.45897 5.23959 9.61702 5.03761 9.61702H5.04949Z"
        fill={isBlack?"black":"white"}
      />
    </g>
    <defs>
      <clipPath id="clip0_205_2123">
        <rect width="12" height="20" fill={isBlack?"black":"white"} />
      </clipPath>
    </defs>
  </svg>
);
