//Packages Imports
import { useSelector } from "react-redux";

//App Import
import { useAppDispatch } from "../../../../../redux/store";
import useAuthHandler from "../../../../../shared/hooks/useAuthHandler";
import { sessionAPIs } from '../../../../../api'
import {
  selectData,
  selectStatus,
  selectDataInProcess,
  activeEditProcess,
} from "../../../../../redux/sessions";

export function useEditSessionHandler() {
  const { token } = useAuthHandler();

  const dispatch = useAppDispatch();
  const status = useSelector((state) => selectStatus(state));
  const data = useSelector((state) => selectData(state));

  const sessionOnProcessing = useSelector((state) =>
    selectDataInProcess(state)
  );

  const onEditSession = (id, values) => {
    if(status.postMessage === 'pending' || status.postMessage === 'fulfilled') return
    if (status.edit === "idle" && token) {
      dispatch(sessionAPIs.putSession({ id, values, token }));
    }
  };

  const onResetStatus = async() => {
    dispatch(activeEditProcess());
  };

  // derive status booleans for ease of use
  const isUninitialized = status.edit === "idle";
  const isLoading = status.edit === "pending";
  const isError = status.edit === "rejected";
  const isSuccess = status.edit === "fulfilled";

  return {
    data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onEditSession,
    onResetStatus,
    processingId: sessionOnProcessing.edit.id,
  };
}
