import sessionsSlice from "./slice"

// SELECTORS
export const selectStatus = (state) => state.sessions.status;
export const selectData = (state) => state.sessions.data;
export const selectDownloadType = (state) => state.sessions.downloadType;
export const setectSampleRecord = (state) => state.sessions.sampleRecords;
export const selectMatchFile = (state) => state.sessions.matchFile;
export const selectCurrentSession = (state) => state.sessions.currentSession;
export const selectCurrentSessionChannel = (state) => state.sessions.channel;
export const selectChannelAgentStatus = (state) => state.sessions.channelAgentStatus
export const selectRecentMessageInfo = (state) => state.sessions.recentMessageInfo;
export const selectCurrentMessage = (state) => state.sessions.currentMessage;
export const selectDataInProcess = (state) => state.sessions.processing;
export const selectCurrentSessionHistory = (state) => state.sessions.chatHistory;
export const selectLastAIResponse = (state) => state.sessions.AIresponse;

// ACTIONS
export const {
  setCurrentSession,
  parseStreamToBlockText,
  resetCurrentSession,
  setDownloadType,
  setCurrentMessage,
  setRecentMessageInfo,
  resetCreateStatus,
  activeEditProcess,
  setMessageQueue,
  setChannelAgentStatus,
  resetPostMessageStatus
} = sessionsSlice.actions;

// REDUCER
export default sessionsSlice.reducer