export const CustomPinIcon = ()=> <svg
xmlns="http://www.w3.org/2000/svg"
width="15"
height="15"
viewBox="0 0 8 8"
fill="none"
>
<path
  d="M0.773566 3.53881L4.4313 7.16591C4.56363 7.29713 4.78606 7.2563 4.86489 7.08773C5.13941 6.49863 5.21085 5.83973 5.07923 5.21398C5.06409 5.14244 5.07852 5.0681 5.12041 5.00773L6.41415 3.14724C6.47996 3.05266 6.59927 3.01078 6.71048 3.04324C7.05151 3.1434 7.40909 3.17411 7.76032 3.13467C7.98416 3.10955 8.08094 2.84047 7.92151 2.68273L5.29426 0.0781798C5.13483 -0.0799147 4.86349 0.0160588 4.8385 0.238019C4.79908 0.586316 4.8297 0.940545 4.93071 1.27907C4.96344 1.389 4.92121 1.50766 4.82583 1.57292L2.94963 2.85618C2.88909 2.89771 2.81378 2.91201 2.74163 2.89701C2.11095 2.76648 1.44613 2.83733 0.852401 3.10955C0.682413 3.18737 0.641236 3.40828 0.773566 3.53951V3.53881Z"
  fill="#CFAF5F"
/>
<path
  d="M0.034854 7.96545C-0.0084349 7.92253 -0.0119543 7.85447 0.0264074 7.80701L2.04937 5.32671L2.69554 5.96746L0.194636 7.97348C0.147124 8.01152 0.0781429 8.00803 0.034854 7.9651V7.96545Z"
  fill="#CFAF5F"
/>
</svg>