//Packages Imports
import { useSelector } from "react-redux";
//App Import
import { useAppDispatch } from "../../../../../redux/store";
import useAuthHandler from "../../../../../shared/hooks/useAuthHandler";
import { sessionAPIs } from "../../../../../api";
import {
  selectCurrentSession,
  selectCurrentSessionHistory,
  selectStatus,
  selectLastAIResponse,
  setMessageQueue,
  selectCurrentMessage,
  parseStreamToBlockText,
  setChannelAgentStatus,
  resetPostMessageStatus
} from "../../../../../redux/sessions";
import { useEffect } from "react";

export function usePostChatHandler() {
  const { token } = useAuthHandler();

  const dispatch = useAppDispatch();
  const status = useSelector((state) => selectStatus(state));
  const currentSession = useSelector((state) => selectCurrentSession(state));
  const currentMessage = useSelector((state)=>selectCurrentMessage(state))

  const currentSessionHistory = useSelector((state) =>
    selectCurrentSessionHistory(state)
  );
  const lastAIResponse = useSelector((state) => selectLastAIResponse(state));

  const onPostChat = (values) => {
    
    if (currentSession && (status.postMessage === "idle" ||  status.postMessage === "rejected") && token) {
      const postMessage = (params) => async (dispatch) => {
        await dispatch(setMessageQueue({ prompt:params.prompt, id: "me" }))
        return await dispatch(
            sessionAPIs.postMessageSession({ id: currentSession.id, values:{prompt:params.prompt,parent_message_id:currentMessage?.id}, token })
          )
      }
      dispatch(postMessage({prompt:values.prompt}))
      
    }
  };
  const onParseStreamToBlockText = (text)=> {
    dispatch(parseStreamToBlockText({text}))
  }

  // derive status booleans for ease of use
  const isUninitialized = status.postMessage === undefined;
  const isLoading =
    status.postMessage === "pending" || status.postMessage === undefined;
  const isError = status.postMessage === "rejected";
  const isSuccess = status.postMessage === "fulfilled";

  useEffect(()=>{
    if(isSuccess){
      dispatch(setChannelAgentStatus('Typing...'))
    }
  },[isSuccess,dispatch])

  const onResetPostMessageStatus = ()=>dispatch(resetPostMessageStatus())

  return {
    data: currentSessionHistory,
    onResetPostMessageStatus,
    onPostChat,
    onParseStreamToBlockText,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    lastAIResponse,
  };
}
