//Packages Imports
import { useSelector } from "react-redux";
//App Import
import { useAppDispatch } from "../../../../redux/store";
import { handleSnackbar, selectSnackbarConfig } from "../../../../redux/utilities";

export function useSnackbarHandler() {
  const dispatch = useAppDispatch();
  const openSnackbar = (props) => dispatch(handleSnackbar({ ...props, open: true }));
  const closeSnackbar = () => dispatch(handleSnackbar({ open: false, text:'' }));

  const snackbarConfig = useSelector((state) => selectSnackbarConfig(state));
  return { snackbarConfig, openSnackbar, closeSnackbar };
}
