//Packages Imports
import { useSelector } from "react-redux";

//App Import
import { useAppDispatch } from "../../../../../redux/store";
import useAuthHandler from "../../../../../shared/hooks/useAuthHandler";
import { sessionAPIs } from '../../../../../api'
import {
    
    selectData,
    selectStatus,
    selectDataInProcess,
  } from "../../../../../redux/sessions";

export function usePinSessionHandler(id) {
  const { token } = useAuthHandler();

  const dispatch = useAppDispatch();
  const status = useSelector((state) => selectStatus(state));
  const data = useSelector((state) => selectData(state));

  const sessionOnProcessing = useSelector((state) =>
    selectDataInProcess(state)
  );

  const onPinSession = (id, value) => {
    if (status.pin === "idle" && token) {
      dispatch(sessionAPIs.putPinSessions({ token, id, value }));
    }
  };

  // derive status booleans for ease of use
  const isUninitialized = status.pin === "idle";
  const isLoading = status.pin === "pending";
  const isError = status.pin === "rejected";
  const isSuccess = status.pin === "fulfilled";

  return {
    data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onPinSession,
    processingId: sessionOnProcessing.pin.id,
  };
}
