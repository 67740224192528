const handleSnackbar = (state, action) => {
  state.snackbar.open = action.payload.open;
  state.snackbar.text = action.payload.text;
  state.snackbar.type = action.payload.type;
  if (action.payload.duration) {
    state.snackbar.duration = action.payload.duration;
  } else {
    state.snackbar.duration = 4000;
  }
};
export default handleSnackbar;
