const setMessageQueue = (state, action) => {
  state.chatHistory.push({
    ai_message: null,
    ai_message_date: new Date(),
    isStream: true,
    created_by: action.payload.by,
    created_date: new Date(),
    human_message: action.payload.prompt,
    id: new Date().getUTCMilliseconds(),
    last_modified_by: null,
    last_modified_date: "",
    sessionid: state.currentSession.id,
  });
  state.processing.postMessage = action.payload;
  state.status.postMessage = "onqueue";
  state.channelAgentStatus = "receiving question";
};

export default setMessageQueue;
