import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentSession,
  selectCurrentSession,
  selectCurrentSessionChannel,
  selectChannelAgentStatus,
  setChannelAgentStatus,
  selectStatus
} from "../../../../../redux/sessions";
import { useEffect } from "react";

export default function useCurrentSessionHandler() {
  const currentSession = useSelector((state) => selectCurrentSession(state));
  const channel = useSelector((state)=>selectCurrentSessionChannel(state))
  const channelAgentStatus = useSelector((state)=>selectChannelAgentStatus(state))
  const messageStatus = useSelector((state) => selectStatus(state));
  const dispatch = useDispatch();
  const setSession = (session) => {
    if(messageStatus.postMessage === 'pending' || messageStatus.postMessage === 'fulfilled') return
    if(currentSession?.id !== session?.id){
      dispatch(setCurrentSession(session));
    }
  };

  useEffect(()=>{
    if(channel){
      channel.bind('agent_update', (data) =>{
        dispatch(setChannelAgentStatus(data.action_tool));
      })
    }
  },
  [channel])// eslint-disable-line react-hooks/exhaustive-deps
  
  const onSetChannelStatus = (val)=>dispatch(setChannelAgentStatus(val))
  return {
    onSetChannelStatus,
    currentSession,
    channelAgentStatus,
    setSession,
    isStreamingMessage:messageStatus.postMessage === 'pending' || messageStatus.postMessage === 'fulfilled',
  };
}
