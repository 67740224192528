import { configureStore } from "@reduxjs/toolkit";
//reducers
import userReducer from './profile'
import sessionsReducer from "./sessions";
import authReducer from "./auth";
import utilityReducer from "./utilities"

export const store = configureStore({
  reducer: {
    user: userReducer,
    sessions: sessionsReducer,
    auth: authReducer,
    utility: utilityReducer
  }
  ,middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const useAppDispatch = () => store.dispatch

