//Packages Imports
import { useSelector } from "react-redux";

//App Import
import { useAppDispatch } from "../../../../../redux/store";
import useAuthHandler from "../../../../../shared/hooks/useAuthHandler";
import { sessionAPIs } from '../../../../../api'
import {
  selectData,
  selectStatus,
  selectDataInProcess,
  resetCreateStatus,
} from "../../../../../redux/sessions";
import { useEffect } from "react";

export function useCreateSessionHandler() {
  const { token } = useAuthHandler();

  const dispatch = useAppDispatch();
  const status = useSelector((state) => selectStatus(state));
  const data = useSelector((state) => selectData(state));

  useEffect(()=>{
    if (data && status.get === 'fulfilled' && data.length === 0 && status.create === "idle" && token) {
      dispatch(sessionAPIs.createSession({ token }));
    }
  },[data, status.get, status.create]);// eslint-disable-line react-hooks/exhaustive-deps

  const sessionOnProcessing = useSelector((state) =>
    selectDataInProcess(state)
  );

  const onCreateSession = () => {
    if(status.postMessage === 'pending' || status.postMessage === 'fulfilled') return
    if (status.create === "idle" && token) {
      dispatch(sessionAPIs.createSession({ token }));
    }
  };

  const onResetStatus = async() => {
    await dispatch(resetCreateStatus());
  };

  // derive status booleans for ease of use
  const isUninitialized = status.create === "idle";
  const isLoading = status.create === "pending";
  const isError = status.create === "rejected";
  const isSuccess = status.create === "fulfilled";

  return {
    data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    onCreateSession,
    onResetStatus,
    processingId: sessionOnProcessing.create.id,
  };
}
