// CHAT HISTORY MEESAGE
export const getCheckpointMessageHistorypending = (state, action) => {
  state.status.chatHistory = "pending";
  state.processing.chatHistory.id = action.meta.arg.id;
  state.chatHistory = [];
};
export const getCheckpointMessageHistoryfulfilled = (state, action) => {
  state.status.chatHistory = "fulfilled";
  state.chatHistory = action.payload;
};
export const getCheckpointMessageHistoryrejected = (state, action) => {
  state.status.chatHistory = "rejected";
};
