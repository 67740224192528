import activeEditProcess from "./cases/activeEditProcess";
import parseStreamToBlockText from "./cases/parseStreamToBlockText";
import resetCreateStatus from "./cases/resetCreateStatus";
import resetCurrentSession from "./cases/resetCurrentSession";
import resetPostMessageStatus from "./cases/resetPostMessageStatus";
import setChannelAgentStatus from "./cases/setChannelAgentStatus";
import setCurrentMessage from "./cases/setCurrentMessage";
import setCurrentSession from "./cases/setCurrentSession";
import setDownloadType from "./cases/setDownloadType";
import setMessageQueue from "./cases/setMessageQueue";
import setRecentMessageInfo from "./cases/setRecentMessageInfo";

const cases = {
  resetCurrentSession: resetCurrentSession,
  setCurrentSession: setCurrentSession,
  setChannelAgentStatus: setChannelAgentStatus,
  parseStreamToBlockText: parseStreamToBlockText,
  setDownloadType: setDownloadType,
  setCurrentMessage: setCurrentMessage,
  setRecentMessageInfo: setRecentMessageInfo,
  resetCreateStatus: resetCreateStatus,
  activeEditProcess: activeEditProcess,
  setMessageQueue,
  resetPostMessageStatus
};

export default cases;
