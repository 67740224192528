export const CustomHistoryIcon = () => <svg
xmlns="http://www.w3.org/2000/svg"
width="18"
height="25"
viewBox="0 0 23 25"
fill="none"
>
<g clipPath="url(#clip0_205_2162)">
  <path
    d="M10.6411 25C9.50302 25 8.35773 24.839 7.22694 24.517C5.38578 23.99 3.74033 23.0679 2.32684 21.7799C2.21811 21.6847 2.11663 21.5823 2.01515 21.4871C1.82669 21.3042 1.81944 21.0041 2.00065 20.8138C2.18187 20.6235 2.47907 20.6162 2.66753 20.7992C2.76176 20.8943 2.856 20.9821 2.95748 21.07C4.26224 22.2556 5.78446 23.1118 7.48064 23.5949C10.4163 24.4438 13.5043 24.0852 16.1718 22.5849C18.8393 21.0846 20.7747 18.6256 21.6083 15.6616C23.3335 9.54333 19.8106 3.13964 13.7507 1.39784C9.95968 0.307379 5.93668 1.23683 2.99372 3.89344C2.2761 4.54479 1.63822 5.28396 1.10182 6.08899C0.956848 6.30855 0.666901 6.3671 0.449441 6.22073C0.231982 6.07436 0.173992 5.78162 0.318966 5.56206C0.898858 4.68384 1.58748 3.88613 2.37034 3.18355C5.55975 0.314698 9.91619 -0.695255 14.0189 0.483023C20.579 2.3712 24.399 9.30182 22.5289 15.9251C21.6228 19.1306 19.5352 21.7945 16.6429 23.4192C14.78 24.4657 12.7214 25 10.6483 25H10.6411Z"
    fill="white"
  />
  <path
    d="M5.90766 6.44028H0.471163C0.210211 6.44028 0 6.22804 0 5.96458V0.475703C0 0.212237 0.210211 0 0.471163 0C0.732115 0 0.942326 0.212237 0.942326 0.475703V5.48888H5.90766C6.16861 5.48888 6.37882 5.70111 6.37882 5.96458C6.37882 6.22804 6.16861 6.44028 5.90766 6.44028Z"
    fill="white"
  />
  <path
    d="M15.0554 16.3788C14.954 16.3788 14.8597 16.3495 14.7727 16.2837L10.3221 12.8806C10.2061 12.7927 10.1336 12.6537 10.1336 12.5V3.45434C10.1336 3.19087 10.3438 2.97864 10.6048 2.97864C10.8657 2.97864 11.0759 3.19087 11.0759 3.45434V12.2658L15.3381 15.5225C15.5484 15.6836 15.5846 15.9763 15.4324 16.1885C15.3381 16.3129 15.2004 16.3788 15.0554 16.3788Z"
    fill="white"
  />
</g>
<defs>
  <clipPath id="clip0_205_2162">
    <rect width="23" height="25" fill="white" />
  </clipPath>
</defs>
</svg>