// SEND MEESAGE
export const postMessageSessionPending = (state, action) => {
  state.status.postMessage = "pending";
};
export const postMessageSessionFulfilled = (state, action) => {
  state.status.postMessage = "fulfilled";
  state.chatHistory[state.chatHistory.length - 1].isStream = true;
  state.chatHistory[state.chatHistory.length - 1].ai_message = action.payload;
  state.chatHistory[state.chatHistory.length - 1].ai_message_date = new Date();
};
export const postMessageSessionRejected = (state, action) => {
  state.status.postMessage = "idle";
};
