import axios from "axios";
import routes from "../routes";
import { buildUrl } from "../helpers/url";
import { getHeaders } from "../helpers/headers";
import { handleResponse } from "../helpers/handleResponse";
import { createAsyncThunk } from "@reduxjs/toolkit";

const postAccountCheck = createAsyncThunk(
    "auth/accountCheck",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axios.post(
          buildUrl(routes.auth.rootPath()),
          { ...data },
          getHeaders(data.token)
        );
        return handleResponse(response, rejectWithValue);
      } catch (error) {
      }
    }
  );

  const account = {
    postAccountCheck
  };
  
  export default account