export const CustomDownloadIcon = ({color})=><svg
width="12"
height="11"
viewBox="0 0 12 11"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
  d="M10.5419 6.6395L10.5419 8.55339C10.5419 9.19747 9.94413 9.72158 9.20952 9.72158L2.79048 9.72158C2.05587 9.72158 1.4581 9.19747 1.4581 8.55339L1.4581 6.63949C1.4581 6.28645 1.13138 6 0.728721 6C0.326059 6 5.62085e-08 6.28645 5.19985e-08 6.63949L2.91756e-08 8.55339C1.30886e-08 9.90241 1.25186 11 2.79048 11L9.20952 11C10.7481 11 12 9.90241 12 8.55339L12 6.6395C12 6.28645 11.6733 6 11.2706 6C10.868 6 10.5412 6.28645 10.5412 6.6395L10.5419 6.6395Z"
  fill={color}
/>
<path
  d="M5.56582 7.83824C5.57383 7.84465 5.58183 7.85042 5.58983 7.85684C5.59969 7.86453 5.60954 7.87287 5.62001 7.87992C5.62986 7.88634 5.63971 7.89275 5.64956 7.89852C5.6588 7.90429 5.66803 7.9107 5.67727 7.91583C5.68712 7.92161 5.69759 7.92609 5.70806 7.93122C5.71791 7.93635 5.72776 7.94148 5.73823 7.94597C5.74808 7.95046 5.75855 7.95367 5.76902 7.95752C5.7801 7.96136 5.79057 7.96585 5.80165 7.9697C5.81212 7.97291 5.82259 7.97547 5.83244 7.97804C5.84352 7.98124 5.85461 7.98445 5.86631 7.98637C5.87862 7.98894 5.89032 7.99022 5.90263 7.99215C5.91249 7.99343 5.92234 7.99535 5.93281 7.99663C5.97776 8.00112 6.02332 8.00112 6.06827 7.99663C6.07874 7.99535 6.08859 7.99343 6.09844 7.99215C6.11076 7.99022 6.12246 7.98894 6.13477 7.98637C6.14647 7.98381 6.15755 7.9806 6.16864 7.97804C6.1791 7.97547 6.18957 7.97291 6.19942 7.9697C6.21051 7.96649 6.22098 7.96201 6.23206 7.95752C6.24253 7.95367 6.25238 7.95046 6.26285 7.94597C6.27331 7.94148 6.28255 7.93635 6.2924 7.93122C6.30287 7.92609 6.31334 7.92161 6.32319 7.91583C6.33304 7.9107 6.34166 7.90429 6.3509 7.89852C6.36075 7.89211 6.3706 7.88634 6.38045 7.87992C6.39092 7.87287 6.40077 7.86453 6.41063 7.85684C6.41863 7.85042 6.42664 7.84465 6.43464 7.83824C6.45188 7.82349 6.46912 7.80746 6.48513 7.79079L8.79911 5.38089C9.06696 5.10194 9.06696 4.64985 8.79911 4.37089C8.53126 4.09194 8.09716 4.09194 7.82931 4.37089L6.68587 5.56173L6.68587 0.714374C6.68587 0.319993 6.37861 -1.14585e-07 5.99992 -1.31138e-07C5.62124 -1.4769e-07 5.31398 0.319993 5.31398 0.714374L5.31398 5.56173L4.17054 4.37089C3.90269 4.09194 3.46858 4.09194 3.20073 4.37089C3.06712 4.51005 3 4.69281 3 4.87557C3 5.05834 3.06712 5.2411 3.20073 5.38025L5.51471 7.79014C5.53072 7.80682 5.54796 7.82349 5.56582 7.83824Z"
  fill={color}
/>
</svg>