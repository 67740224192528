import handleSnackbar from "./cases/handleSnackbar";
import parseHelpStreamToBlockText from "./cases/parseHelpStreamToBlockText";
import resetEmailStatus from "./cases/resetEmailStatus";
import resetHistory from "./cases/resetHistory";
import setHelpMessageStatus from "./cases/setHelpMessageStatus";
import setHelpMessageQueue from "./cases/setHelpMessageQueue";

const cases = {
  parseHelpStreamToBlockText,
  setHelpMessageStatus,
  setHelpMessageQueue,
  resetEmailStatus,
  handleSnackbar,
  resetHistory
};

export default cases;
