import { styled } from "@mui/material/styles";
import { Box, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MuiDrawer from "@mui/material/Drawer";
import React from "react";
const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerHeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  zIndex:1000,
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),

}));
export default function CustomDrawer({
  body,
  icon,
  headerActions,
  open,
  handleDrawer,
  style,
  drawerChevronColor,
  openFullChat,
  onOpenFullChat,
  isChat
}) {
  return (
    <Drawer
      PaperProps={{
        sx: {...style,
          height:'100%',
          top:'50px'},
      }}
      variant="permanent"
      open={open}
    >
      <Box>
        <DrawerHeaderContainer>
          <Box>{icon ? icon : ""}</Box>
          <DrawerHeader>
            {headerActions && open && headerActions}
            <IconButton sx={{color:drawerChevronColor}}  onClick={handleDrawer}>
              {!open ? <ChevronRightIcon color="white" /> : <ChevronLeftIcon />}
            </IconButton>
           {isChat && open && !openFullChat &&<IconButton sx={{color:drawerChevronColor}}  onClick={onOpenFullChat}>
              {!openFullChat ? <ChevronRightIcon  /> : <ChevronLeftIcon />}
            </IconButton>
          }
          </DrawerHeader>
        </DrawerHeaderContainer>
      </Box>
      {open && body}
    </Drawer>
  );
}
