// CHAT HISTORY MEESAGE
export const getChatMessageSessionPending = (state, action) => {
  state.status.chatHistory = "pending";
  state.processing.chatHistory.id = action.meta.arg.id;
  state.chatHistory = [];
};
export const getChatMessageSessionFulfilled = (state, action) => {
  state.status.chatHistory = "fulfilled";
  state.chatHistory = action.payload;
};
export const getChatMessageSessionRejected = (state, action) => {
  state.status.chatHistory = "rejected";
};
