//Packages Imports
import { useEffect } from "react";
import { useSelector } from "react-redux";
//App Import
import { useAppDispatch } from "../../../../../redux/store";
import useAuthHandler from "../../../../../shared/hooks/useAuthHandler";
import { sessionAPIs } from '../../../../../api'
import {
    selectCurrentSession,
    selectCurrentSessionHistory,
    selectCurrentMessage,
    selectStatus,
  } from "../../../../../redux/sessions";

export function useGetChatHandler() {
  const { token } = useAuthHandler();

  const dispatch = useAppDispatch();
  const status = useSelector((state) => selectStatus(state));
  const currentSession = useSelector((state) => selectCurrentSession(state));

  const currentMessage = useSelector((state) => selectCurrentMessage(state));
  const currentSessionHistory = useSelector((state) => selectCurrentSessionHistory(state));

  useEffect(() => {
    if (currentSession && status.chatHistory === 'idle' && token) {
      if(currentMessage){
        dispatch(sessionAPIs.getCheckpointMessageHistory({id:currentSession.id,currentMessageId: currentMessage.id, token}));  
        return
      }
      dispatch(sessionAPIs.getChatMessageSession({id:currentSession.id, token}));
    }
  }, [status, token, dispatch, currentSession]);// eslint-disable-line react-hooks/exhaustive-deps

  // derive status booleans for ease of use
  const isUninitialized = status.chatHistory === undefined;
  const isLoading = status.chatHistory === "pending" || status.chatHistory === undefined;
  const isError = status.chatHistory === "rejected";
  const isSuccess = status.chatHistory === "fulfilled";

  return { data:currentSessionHistory, isUninitialized, isLoading, isError, isSuccess };
}
