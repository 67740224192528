import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getHeaders } from "../helpers/headers";
import { graphConfig } from "../../authConfig";

const getAzureUserProfile = createAsyncThunk(
  "auth/getAzureUserProfile",
  async (token, { rejectWithValue }) => {
    const response = await axios.get(
      graphConfig.graphMeEndpoint,
      getHeaders(token)
    );
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue(response);
    }
    return response.data;
  }
);

const azureGraphAPI = {
  getAzureUserProfile,
};

export default azureGraphAPI;
