// CREATE SESSION
export const postAccountCheckPending = (state, action) => {
    state.account.status = "pending";
  };
  export const postAccountCheckFulfilled = (state, action) => {
    state.account.status = "fulfilled";
    state.account.data = action.payload;
  };
  export const postAccountCheckRejected = (state, action) => {
    console.log(state, action)
    state.account.status = "rejected";
  };
  