export const CustomMessagesIcon = ()=><svg xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 34 25" fill="none">
<g clipPath="url(#clip0_207_2569)">
<path d="M33.5274 25C33.4692 25 33.4038 24.9854 33.3456 24.9635L29.8845 23.5389C28.423 24.4959 26.7288 25 24.9765 25C23.3477 25 21.7553 24.5616 20.3666 23.7288C20.1411 23.5973 20.0684 23.3051 20.1993 23.0786C20.3302 22.8521 20.621 22.7791 20.8465 22.9106C22.0898 23.6557 23.515 24.0429 24.9692 24.0429C26.6125 24.0429 28.1976 23.5462 29.55 22.611C29.6809 22.5234 29.8482 22.5014 30.0009 22.5599L32.7784 23.6996L31.8259 20.5143C31.7896 20.3828 31.8041 20.244 31.8768 20.1344C32.6403 18.8705 33.0475 17.4167 33.0475 15.9263C33.0475 12.5219 30.9098 9.45353 27.725 8.29192C27.4778 8.20426 27.3542 7.92664 27.4414 7.68555C27.5287 7.44447 27.7977 7.31296 28.0449 7.40063C31.6005 8.69374 33.9927 12.1201 33.9927 15.9263C33.9927 17.5117 33.5783 19.0678 32.793 20.4413L33.9709 24.379C34.0218 24.5543 33.9709 24.7443 33.8328 24.8685C33.7455 24.9489 33.6292 24.9927 33.5128 24.9927L33.5274 25Z" fill="black"/>
<path d="M6.74037 13.552C5.53336 13.552 4.55902 12.5657 4.55902 11.3603C4.55902 10.1549 5.54063 9.16861 6.74037 9.16861C7.94012 9.16861 8.92172 10.1549 8.92172 11.3603C8.92172 12.5657 7.94012 13.552 6.74037 13.552ZM6.74037 10.1183C6.05688 10.1183 5.50427 10.6736 5.50427 11.3603C5.50427 12.047 6.05688 12.6023 6.74037 12.6023C7.42386 12.6023 7.97647 12.047 7.97647 11.3603C7.97647 10.6736 7.42386 10.1183 6.74037 10.1183Z" fill="black"/>
<path d="M19.2831 13.552C18.0761 13.552 17.1018 12.5657 17.1018 11.3603C17.1018 10.1549 18.0834 9.16861 19.2831 9.16861C20.4829 9.16861 21.4645 10.1549 21.4645 11.3603C21.4645 12.5657 20.4829 13.552 19.2831 13.552ZM19.2831 10.1183C18.5997 10.1183 18.047 10.6736 18.047 11.3603C18.047 12.047 18.5997 12.6023 19.2831 12.6023C19.9666 12.6023 20.5192 12.047 20.5192 11.3603C20.5192 10.6736 19.9666 10.1183 19.2831 10.1183Z" fill="black"/>
<path d="M1.03978 23.8603C0.937981 23.8603 0.836185 23.8238 0.748931 23.758C0.596236 23.6411 0.530796 23.4439 0.581694 23.2613L2.08683 17.5263C0.719846 15.6853 0 13.5593 0 11.353C0 5.09205 5.83875 0 13.0081 0C20.1775 0 26.0235 5.09205 26.0235 11.353C26.0235 17.614 20.1848 22.706 13.0081 22.706C10.5868 22.706 8.23097 22.1216 6.1805 21.0184L1.27246 23.7946C1.19974 23.8384 1.11976 23.853 1.03978 23.853V23.8603ZM13.0081 0.949737C6.355 0.949737 0.945252 5.61806 0.945252 11.353C0.945252 13.4205 1.64328 15.415 2.97391 17.1318C3.06116 17.2487 3.09752 17.4021 3.06116 17.5409L1.78144 22.4138L5.94782 20.0614C6.09324 19.981 6.26775 19.981 6.41317 20.0614C8.37639 21.1718 10.6595 21.7563 13.0154 21.7563C19.6685 21.7563 25.0783 17.088 25.0783 11.353C25.0783 5.61806 19.6612 0.949737 13.0081 0.949737Z" fill="black"/>
<path d="M13.0081 13.552C11.8011 13.552 10.8268 12.5657 10.8268 11.3603C10.8268 10.1549 11.8084 9.16861 13.0081 9.16861C14.2079 9.16861 15.1895 10.1549 15.1895 11.3603C15.1895 12.5657 14.2079 13.552 13.0081 13.552ZM13.0081 10.1183C12.3246 10.1183 11.772 10.6736 11.772 11.3603C11.772 12.047 12.3246 12.6023 13.0081 12.6023C13.6916 12.6023 14.2442 12.047 14.2442 11.3603C14.2442 10.6736 13.6916 10.1183 13.0081 10.1183Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_207_2569">
<rect width="34" height="25" fill="white"/>
</clipPath>
</defs>
</svg>