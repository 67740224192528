// DOWNLOAD MATCHFILE
export const downloadFilePending = (state, action) => {
    state.status.matchFile = "pending";
}
export const downloadFileFulfilled = (state, action) => {
    state.status.matchFile = "fulfilled";
    state.matchFile = action.payload;
}
export const downloadFileRejected = (state, action) => {
    state.status.matchFile = "idle";
}