import {
  Box,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
  IconButton,
} from "@mui/material";
import { usePostChatHandler } from "../hooks/usePostChatHandler";
import { useEffect, useState } from "react";
import useCurrentSessionHandler from "../../sessions/hooks/useCurrentSessionHandler";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbarHandler } from "../../../../../shared/layouts/EmailModalsLayout/hooks/useSnackbarHandler";

export function Bubble({ message, scrollToBottom, disabled, isLastItem }) {
  const { isLoading, onParseStreamToBlockText, onResetPostMessageStatus } =
    usePostChatHandler();
  const [responseText, setResponseText] = useState("");
  const [streamDone, setStreamDone] = useState(false);
  const { channelAgentStatus } = useCurrentSessionHandler();

  useEffect(() => {
    const getMessage = async () => {
      if (message.isStream && message.ai_message) {
        while (true) {
          const { done, value } = await message.ai_message.read();
          if (done) {
            onResetPostMessageStatus("idle");
            setStreamDone(true);
            break;
          }
          const text = new TextDecoder().decode(value);
          // console.log('TEXT:',text)
          setResponseText((prevText) => `${prevText ? prevText : ""}${text}`); // Update the state with the new text
        }
      } else {
        setResponseText(message.ai_message);
      }
    };
    getMessage();
  }, [message]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (streamDone && responseText) {
      onParseStreamToBlockText(responseText);
    }
  }, [streamDone]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (scrollToBottom) {
      scrollToBottom();
    }
  }, [responseText]); // eslint-disable-line react-hooks/exhaustive-deps

  const theme = useTheme();

  const generateRandomColor = () => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  };

  const generateRandomGradient = () => {
    const colors = Array.from({ length: 2 }, () => generateRandomColor());
    return `linear-gradient(to right, ${colors.join(", ")})`;
  };

  const { openSnackbar } = useSnackbarHandler();
  const copyToClipart = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      openSnackbar({ text: "Copied", duration: 2000, type: "success" });
    } catch (err) {
      console.error("Error copying text: ", err);
    }
  };

  const parseDate = (date) =>
    date
      ? new Date(date).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
      : "";

  const bubbleFooter = (showAction, direction) => (
    <Stack
      my={1}
      mx={5}
      direction={direction === "left" ? "row" : "row-reverse"}
      spacing={0.5}
    >
      <Box>
        <Typography variant="overline" color={theme.palette.text}>
          {direction === "left"
            ? parseDate(message.ai_message_date)
            : parseDate(message.created_date)}
        </Typography>
      </Box>
      {showAction && (
        <Tooltip title="Copy">
          <IconButton
            size="small"
            sx={{
              bgcolor: theme.palette.info.light,
              mx: 5,
              my: 1,
            }}
            onClick={() => copyToClipart(responseText)}
          >
            <ContentCopyIcon fontSize="small"></ContentCopyIcon>
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );

  const messageLoader = (time) => (
    <Box>
      <LinearProgress
        color="primary"
        sx={{
          transform: "rotate(90deg)",
          width: "15px",
          height: "15px",
          backgroundColor: "black",
          "& .MuiLinearProgress-bar": {
            animationDuration: time,
            backgroundImage: generateRandomGradient(),
          },
        }}
      ></LinearProgress>
    </Box>
  );
  return (
    <Box>
      <Box
        sx={{
          my: 3,
          display: "flex",
          alignContent: "flex-start",
          flexDirection: message.human_message ? "row-reverse" : "row",
        }}
        maxWidth={"100%"}
      >
        <Box maxWidth={"70%"}>
          <Box
            position="absolute"
            sx={{
              backgroundImage: "url('/top-vector-tip.png')",
              filter: disabled ? "grayscale(0.6)" : "initial",
              height: "10px",
              width: "30px",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
            right={"22px"}
          />
          <Box
            borderRadius={3}
            sx={{
              p: 3,
              width: "auto",
              background: `linear-gradient(90deg, ${theme.palette.primary.main} 9.65%, ${theme.palette.secondary.main} 92.4%)`,
              mx: 5,
              filter: disabled ? "grayscale(0.6)" : "initial",
            }}
          >
            <Typography
              color={theme.palette.info.contrastTextLight}
              whiteSpace={"break-spaces"}
            >
              {message?.human_message}
            </Typography>
          </Box>
          {bubbleFooter(false, "rigth")}
        </Box>
      </Box>
      {(message?.ai_message || message?.isStream) && (
        <Box
          sx={{
            my: 3,
            display: "flex",
            alignContent: "flex-start",
            flexDirection: "row",
          }}
          maxWidth={"100%"}
        >
          <Box maxWidth={"70%"}>
            {(isLastItem && message.isStream && isLoading) ||
            (channelAgentStatus === "Typing..." && responseText === null) ? (
              <Box
                position="relative"
                top={"14px"}
                left={42}
                sx={{
                  display: "flex",
                  alignContent: "flex-start",
                  flexDirection: "row",
                  background: "black",
                  padding: "3px",
                  width: "200px",
                  borderRadius: "10px",
                  paddingTop: "8px",
                }}
              >
                {messageLoader("1s")}
                {messageLoader("0.8s")}
                {messageLoader("1.6")}
                <Typography
                  pl={1}
                  pb={0.5}
                  color={theme.palette.info.contrastTextLight}
                  textAlign="center"
                  variant="caption"
                  fontSize={9}
                >
                  {" "}
                  {channelAgentStatus}
                </Typography>
              </Box>
            ) : (
              <>
                <Box
                  position="absolute"
                  sx={{
                    backgroundImage: "url('/top-vector-tip-white.png')",
                    filter: disabled ? "grayscale(0.6)" : "initial",
                    height: "10px",
                    width: "30px",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  }}
                  left={"31px"}
                />

                <Box
                  borderRadius={3}
                  sx={{
                    p: 3,
                    width: "auto",
                    bgcolor: theme.palette.info.light,
                    mx: 5,
                  }}
                >
                  <Typography color={"black"} whiteSpace={"break-spaces"}>
                    {responseText}
                  </Typography>
                </Box>
                {bubbleFooter(true, "left")}
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
