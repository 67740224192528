import utilitySlice from "./slice";

export const selectEmailStatus = (state) => state?.utility?.email?.status;
export const selectHelpChatStatus = (state) => state?.utility?.helpMessages?.status;
export const selectHelpChatHistory = (state) => state?.utility?.helpMessages?.history;

export const selectSnackbarConfig = (state) => state?.utility?.snackbar;

// ACTIONS
export const {
  parseHelpStreamToBlockText,
  setHelpMessageStatus,
  setHelpMessageQueue,
  resetEmailStatus,
  handleSnackbar,
  resetHistory
} = utilitySlice.actions;

export default utilitySlice.reducer;
