import * as React from "react";
import { Snackbar, Box } from "@mui/material";

import Sessions from "./sessions";
import Chat from "./chat";
import Summary from "./summary";
import { useSnackbarHandler } from "../../../shared/layouts/EmailModalsLayout/hooks/useSnackbarHandler";
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';

export default function Main() {
  const [openSessions, setOpenSession] = React.useState(true);
  const [openChat, setOpenChat] = React.useState(true);
  const [openFullChat, setOpenFullChat] = React.useState(true);
  const { snackbarConfig, closeSnackbar } = useSnackbarHandler();
  const handleDraweSession = () => {
    setOpenSession(!openSessions);
  };

  const handleDrawerChat = () => {
    if (openChat && openFullChat) {
      setOpenFullChat(false);
      return;
    }
    setOpenChat(!openChat);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Sessions openSessions={openSessions} handleDrawer={handleDraweSession} />
      <Chat
        openFullChat={openFullChat}
        onOpenFullChat={() => setOpenFullChat(!openFullChat)}
        openChat={openChat}
        openSessions={openSessions}
        handleDrawer={handleDrawerChat}
      />
      <Summary
        openFullChat={openFullChat}
        openChat={openChat}
        openSessions={openSessions}
      />
      <Snackbar
        anchorOrigin={{
          vertical: snackbarConfig.vertical,
          horizontal: snackbarConfig.horizontal,
        }}
        TransitionComponent={Slide}
        open={snackbarConfig.open}
        autoHideDuration={snackbarConfig.duration}
        onClose={closeSnackbar}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbarConfig.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarConfig.text}
        </Alert>
      </Snackbar>
    </Box>
  );
}
