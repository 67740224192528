import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentMessage,
  selectCurrentMessage,
  selectStatus
} from "../../../../../redux/sessions";

export default function useCurrentMessaheHandler() {
  const currentMessage = useSelector((state) => selectCurrentMessage(state));
  const dispatch = useDispatch();
  const messageStatus = useSelector((state) => selectStatus(state));

  const setMessage = (session, message) => {
    if(messageStatus.postMessage === 'pending' || messageStatus.postMessage === 'fulfilled') return
    if(session && message === null){
      dispatch(setCurrentMessage({session,message}));
      return
    }
    if(!(message === null && currentMessage=== null)){
      if(!currentMessage || (message?.id !== currentMessage?.id))
      dispatch(setCurrentMessage({session,message}));
    }
  };

  return {
    currentMessage,
    setMessage,
  };
}
