export const handleResponse = (response,rejectWithValue) => {
  if (response.status < 200 || response.status >= 300) {
    if(response.status === 401){
      sessionStorage.clear()
      window.location.reload()
    }
    return rejectWithValue(response);
  }
  return response.data.data;
};
